<template>
    <div>
        <div class="right-menu shipping-right">

            <subHeader pageName="PRODUCT BUNDLES" />

            <tutorialsAction />

            <div class="db_top">
                <div class="db-container">

                    <moduleStats/>

                    <!-- search block starts here -->
                    <div class="formOptionsBox">
                        <div class="searchOptionsWrap">
                            <div class="searchBlock" id="product-bundle-search">
                                <form @submit.prevent="">
                                    <div class="searchIconWrap">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17.491 17.49">
                                            <path id="Path_10" data-name="Path 10" d="M16,17.49h0L11,12.5v-.79l-.27-.28a6.512,6.512,0,1,1,.7-.7l.28.27h.79l4.99,5L16,17.489ZM6.5,2A4.5,4.5,0,1,0,11,6.5,4.505,4.505,0,0,0,6.5,2Z" fill="#848484"/>
                                        </svg>
                                    </div>
                                    <input
                                        type="text"
                                        name="search"
                                        id="search"
                                        ref="searchInput"
                                        placeholder="Search"
                                        v-model="filter.searchedTerm"
                                        @input="searchProductBundleByName()"
                                        :disabled="tourIsActive"
                                    />
                                    <button
                                        class="filterBtnWrap"
                                        type="button"
                                        @click.prevent="filterPopup.isOpen = true"
                                        :disabled="tourIsActive || previewForm.id != ''"
                                        id="product-bundle-filters"
                                    >
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21.749 19.763">
                                            <g id="Group_24770" data-name="Group 24770" transform="translate(-0.001 0)">
                                                <path id="Path_51390" data-name="Path 51390" d="M198.877,227.422c-.479,0-.957.007-1.436,0a.259.259,0,0,0-.284.194,2.964,2.964,0,1,1-.007-2.379.288.288,0,0,0,.317.212q1.459-.014,2.918,0a.985.985,0,0,1,.314,1.917,1.53,1.53,0,0,1-.433.059c-.463.007-.926,0-1.389,0" transform="translate(-179.623 -209.631)" />
                                                <path id="Path_51391" data-name="Path 51391" d="M2.456,113.589c.486,0,.973-.007,1.459,0a.276.276,0,0,0,.3-.2,2.967,2.967,0,1,1,.03,2.42.374.374,0,0,0-.41-.259q-1.447.016-2.895,0a.959.959,0,0,1-.935-.889.978.978,0,0,1,.713-1.035,1.45,1.45,0,0,1,.366-.037c.455,0,.911,0,1.366,0" transform="translate(-0.004 -104.694)" />
                                                <path id="Path_51392" data-name="Path 51392" d="M258.6,0a2.964,2.964,0,1,1-2.976,2.939A2.961,2.961,0,0,1,258.6,0" transform="translate(-239.797)" />
                                                <path id="Path_51393" data-name="Path 51393" d="M6.966,33.771q-2.94,0-5.88,0a.979.979,0,0,1-1-1.22.936.936,0,0,1,.732-.724,1.445,1.445,0,0,1,.344-.033q5.811,0,11.621,0a1.463,1.463,0,0,1,.454.066.982.982,0,0,1-.3,1.909c-.471.011-.941,0-1.412,0H6.966" transform="translate(-0.055 -29.822)" />
                                                <path id="Path_51394" data-name="Path 51394" d="M4.969,255.405c1.3,0,2.609,0,3.914,0a.983.983,0,0,1,.953.675.934.934,0,0,1-.345,1.089,1.272,1.272,0,0,1-.644.207c-2.6.014-5.2.012-7.8.007a.991.991,0,1,1,.013-1.978q1.957,0,3.914,0" transform="translate(0 -239.595)" />
                                                <path id="Path_51395" data-name="Path 51395" d="M196.631,145.668c-1.3,0-2.609,0-3.914,0a.983.983,0,0,1-.112-1.962c.023,0,.046,0,.069,0q3.96,0,7.92,0a.957.957,0,0,1,.961.777.968.968,0,0,1-.919,1.183c-.586.016-1.173.006-1.76.006h-2.246" transform="translate(-179.832 -134.804)" />
                                            </g>
                                        </svg>
                                    </button>
                                </form>
                            </div>
                            <div class="filterShowTabsRow" v-if="allFilters.length != 0">
                                <div class="filterShowTabsList">
                                    <ul>
                                        <li v-for="(filter,index) in allFilters.slice(0,2)" :key="'filter_'+index">
                                            {{ filter.value.name ? filter.value.name.length > 10 ? filter.value.name.substr(0,10) + "..." : filter.value.name : 
                                            filter.value.length > 10 ? filter.value.substr(0,10) + "..." : filter.value }}
                                            <button @click.prevent="removeFromFilter(filter)">
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 5.431 5.431">
                                                    <path id="cross-small" d="M10.653,5.825h0a.4.4,0,0,0-.569,0L8.239,7.67,6.394,5.825a.4.4,0,0,0-.569,0h0a.4.4,0,0,0,0,.569L7.67,8.239,5.825,10.084a.4.4,0,0,0,0,.569h0a.4.4,0,0,0,.569,0L8.239,8.808l1.845,1.845a.4.4,0,0,0,.569,0h0a.4.4,0,0,0,0-.569L8.808,8.239l1.845-1.845A.4.4,0,0,0,10.653,5.825Z" transform="translate(-5.524 -5.524)"/>
                                                </svg>
                                            </button>
                                        </li>
                                        <li class="filterNumbers" v-if="allFilters.length > 2">+{{  allFilters.length - 2 }}</li>
                                    </ul>
                                </div>
                                <div class="filterShowTabsResetBtn">
                                    <button @click.prevent="clearProductBundleFilters">Clear</button>
                                </div>
                            </div>
                            <div class="filterShowTabsRow" v-else-if="allFilters.length == 0 && filter.searchedTerm.length > 0">
                                <div class="filterShowTabsListSearchedQuery">
                                    <p>Search results for "{{ filter.searchedTerm.length > 20 ? filter.searchedTerm.substr(0, 20) + "..." : filter.searchedTerm }}".</p>
                                </div>
                                <div class="filterShowTabsResetBtn">
                                    <button @click.prevent="clearSearch">Clear</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- search block ends here -->

                    <!-- data table starts here -->
                    <div class="tableMainWrapBox" style="margin-top: 25px;position: relative;" id="preview-record">
                        <div class="tableScrollWrap" ref="productBundlesTable">
                            <table>
                                <thead>
                                    <tr>
                                        <th>
                                            <div class="ctmCheckWrap">
                                                <label class="ctmTableCheckContainer">
                                                    <input 
                                                        type="checkbox"
                                                        @click="toggleIsCheckedAll()"
                                                        :checked="checkedItems.length != 0 && checkedItems.length == productBundlesList.length"
                                                        :disabled="tourIsActive || this.previewForm.id != '' "
                                                    >
                                                    <span class="checkmark"></span>
                                                </label>
                                            </div>
                                        </th>
                                        <th>
                                            <div class="ctmCheckWrap">
                                                <div class="ctmTableImgWrap">
                                                    <p data-toggle="tooltip" data-placement="top" title="Image">
                                                        Image
                                                    </p>
                                                </div>
                                            </div>
                                        </th>
                                        <th>
                                            <div class="ctmCheckWrap">
                                                <div class="ctmTableImgWrap">
                                                    <p data-toggle="tooltip" data-placement="top" title="Items">
                                                        Items
                                                    </p>
                                                </div>
                                            </div>
                                        </th>
                                        <th class="ctmPr-3">
                                            <div class="ctmTableTitleWrap">
                                                <p data-toggle="tooltip" data-placement="top" title="Name">Name</p>
                                            </div>
                                        </th>
                                        <th class="ctmPr-3">
                                            <div class="ctmTableTitleWrap">
                                                <p data-toggle="tooltip" data-placement="top" title="Total">Total</p>
                                            </div>
                                        </th>
                                        <th>
                                            <div class="ctmTableTitleWrap">
                                                <p data-toggle="tooltip" data-placement="top" title="Discount">Discount</p>
                                            </div>
                                        </th>
                                        <th>
                                            <div class="ctmTableTitleWrap">
                                                <p data-toggle="tooltip" data-placement="top" title="Discounted Total">Discounted Total</p>
                                            </div>
                                        </th>
                                        <th>
                                            <div class="ctmTableTitleWrap">
                                                <p data-toggle="tooltip" data-placement="top" title="Active/Hide">Active/Hide</p>
                                            </div>
                                        </th>
                                        <th>
                                            <div class="ctmTableTitleWrap">
                                                <p data-toggle="tooltip" data-placement="top" title="Action">Action</p>
                                            </div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody v-if="productBundlesLoading">
                                    <tr v-for="i in 5" :key="i">
                                        <td>
                                            <div class="ctmCheckWrap">
                                                <vue-skeleton-loader
                                                    type="rect"
                                                    :width="20"
                                                    :height="20"
                                                    animation="fade"
                                                />
                                            </div>
                                        </td>
                                        <td>
                                            <div class="ctmCheckWrap">
                                                <div class="ctmDataTableImgBox">
                                                    <vue-skeleton-loader
                                                        type="circle"
                                                        :width="36"
                                                        :height="36"
                                                        animation="fade"
                                                    />
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="ctmCheckWrap">
                                                <div class="ctmDataTableImgBox">
                                                    <vue-skeleton-loader
                                                        type="circle"
                                                        :width="36"
                                                        :height="36"
                                                        animation="fade"
                                                    />
                                                </div>
                                            </div>
                                        </td>
                                        <td class="mainTableTitle">
                                            <div class="editFieldTitle">
                                                <vue-skeleton-loader
                                                    type="rect"
                                                    :width="150"
                                                    :height="20"
                                                    animation="fade"
                                                />
                                            </div>
                                        </td>
                                        <td>
                                            <div class="tableBodyCtmTxt">
                                                <vue-skeleton-loader
                                                    type="rect"
                                                    :width="250"
                                                    :height="20"
                                                    animation="fade"
                                                />
                                            </div>
                                        </td>
                                        <td>
                                            <div class="tableBodyCtmTxt">
                                                <vue-skeleton-loader
                                                    type="rect"
                                                    :width="150"
                                                    :height="20"
                                                    animation="fade"
                                                />
                                            </div>
                                        </td>
                                        <td>
                                            <div class="tableBodyCtmTxt">
                                                <vue-skeleton-loader
                                                    type="rect"
                                                    :width="150"
                                                    :height="20"
                                                    animation="fade"
                                                />
                                            </div>
                                        </td>
                                        <td>
                                            <div class="tableBodyCtmTxt">
                                                <vue-skeleton-loader
                                                    type="rect"
                                                    :width="100"
                                                    :height="20"
                                                    animation="fade"
                                                />
                                            </div>
                                        </td>
                                        <td>
                                            <vue-skeleton-loader
                                                type="rect"
                                                :width="100"
                                                :height="20"
                                                animation="fade"
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody v-else>
                                    <tr v-if="productBundlesList && productBundlesList.length == 0">
                                        <td colspan="10" style="text-align:center"><p class="mb-0">No Product Bundles Available</p></td>
                                    </tr>
                                    <tr v-for="(productBundle, index) in productBundlesList" :key="index">

                                        <!-- checkbox & image block starts here -->
                                        <td @click="previewBundle(productBundle,index,'input',$event)">
                                            <div class="ctmCheckWrap" data-open="true">
                                                <label 
                                                    class="ctmTableCheckContainer" 
                                                    data-open="false" 
                                                    @click.prevent="productBundle.id != -1 ? checkedItemHandle(productBundle.id) : ''"
                                                >
                                                    <input 
                                                        type="checkbox" 
                                                        v-model="checkedItems" 
                                                        :value="productBundle.id" :disabled="tourIsActive || productBundle.id == -1" 
                                                        data-open="false"
                                                    >
                                                    <span class="checkmark" data-open="false"></span>
                                                </label>
                                            </div>
                                        </td>
                                        <td @click.prevent="previewBundle(productBundle, index, 'solid', $event)">
                                            <div class="ctmCheckWrap" data-open="true">
                                                <div style="width: 100%;" data-open="true">
                                                    <div class="ctmDataTableImgBox" data-open="true">
                                                    <img 
                                                        :src="productBundle.image ? base_image_url + settings.store_id + '/product-bundle/' + productBundle.image : '/images/no_image.png'" 
                                                        data-open="true"
                                                        @error="handleImageError"
                                                    />
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                        <!-- checkbox & image block ends here -->

                                        <!-- items block starts here -->
                                        <td @click="previewBundle(productBundle,index,'input',$event)">
                                            <div class="ctmCheckWrap" data-open="true">
                                                <div class="orderTableProducts" data-open="true">
                                                    <div class="ctmDataTableImgBox" v-for="(item, i) in productBundle.bundle_items.slice(0,3)" :key="'item' + i" data-open="true">
                                                        <p v-if="i >= 3" data-open="true">+{{ productBundle.bundle_items.length - 3 }}</p>
                                                        <img 
                                                            :src=" item.item_details.image ? 
                                                            base_image_url + settings.store_id + '/product/' + item.item_details.image : 
                                                            '/images/no_image.png' " 
                                                            data-open="true" 
                                                            @error="handleImageError"
                                                            v-else
                                                        />
                                                    </div>
                                                    <div class="ctmDataTableImgBox" v-if="productBundle.bundle_items.length > 3" data-open="true">
                                                        <p data-open="true">+{{ productBundle.bundle_items.length - 3 }}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                        <!-- items block ends here -->

                                        <!-- product bundle name block starts here -->
                                        <td class="mainTableTitle" @click="previewBundle(productBundle,index,'input',$event)">
                                            <div class="editFieldTitle" data-open="true" id="bundle-name-field-3">
                                                <input 
                                                    type="text" 
                                                    placeholder="Write a product bundle name" 
                                                    v-model="productBundle.name" 
                                                    @focus="bundleSelection(productBundle)" 
                                                    @blur="handleOTGUpdate(productBundle,index)" 
                                                    :disabled="tourIsActive"
                                                    data-open="false"
                                                >
                                            </div>
                                        </td>
                                        <!-- product bundle name block ends here -->

                                        <!-- product bundle total block starts here -->
                                        <td style="position: relative" @click.prevent="previewBundle(productBundle, index,'solid',$event)" data-open="true">
                                            <div class="tableBodyCtmTxt" data-open="true">
                                                <p data-open="true">
                                                    <b data-open="true">
                                                    {{
                                                        productBundle.bundle_total ? settings.currency_unit ? settings.currency_symbol + " " + parseFloat(productBundle.bundle_total).toLocaleString( "ur-PK",
                                                            {
                                                                minimumFractionDigits: 2,
                                                                maximumFractionDigits: 2,
                                                            }
                                                        )
                                                        : 0
                                                        : settings.currency_symbol + " " + parseFloat(productBundle.bundle_total).toLocaleString( "ur-PK",
                                                            {
                                                                minimumFractionDigits: 2,
                                                                maximumFractionDigits: 2,
                                                            }
                                                        )
                                                    }}
                                                    </b>
                                                </p>
                                            </div>
                                        </td>
                                        <!-- product bundle total block ends here -->

                                        <!-- product bundle discount type starts here -->
                                        <td @click.prevent="previewBundle(productBundle, index,'solid',$event)">
                                            <div class="typeSelectBox" data-open="true">
                                                <p>{{ productBundle.discount_type.charAt(0).toUpperCase() + productBundle.discount_type.slice(1) }}</p>
                                            </div>
                                        </td>
                                        <!-- product bundle discount type starts here -->

                                        <!-- product bundle discounted total block starts here -->
                                        <td style="position: relative" @click.prevent="previewBundle(productBundle, index,'solid',$event)" data-open="true">
                                            <div class="tableBodyCtmTxt" data-open="true">
                                                <p data-open="true">
                                                    <b data-open="true">
                                                    {{
                                                        productBundle.discount_type != 'none' ? productBundle.discounted_total ? settings.currency_unit ? settings.currency_symbol + " " + parseFloat(productBundle.discounted_total).toLocaleString( "ur-PK",
                                                            {
                                                                minimumFractionDigits: 2,
                                                                maximumFractionDigits: 2,
                                                            }
                                                        )
                                                        : 0
                                                        : '--' :'--'
                                                    }}
                                                    </b>
                                                </p>
                                            </div>
                                        </td>
                                        <!-- product bundle discounted total block ends here -->
                                        
                                        <!-- active/disabled block starts here-->
                                        <td @click="previewBundle(productBundle,index,'input',$event)">
                                            <div class="actionSwitch" data-open="true">
                                                <label class="switch" data-open="false" v-if="productBundle.id != -1">
                                                    <input
                                                        type="checkbox"
                                                        :id="`customSwitch-p-${productBundle.id}`"
                                                        :value="productBundle.isActive"
                                                        v-model="productBundle.isActive"
                                                        :disabled="tourIsActive"
                                                        data-open="false"
                                                    >
                                                    <span class="slider" data-open="false" @click.stop="updateBundleStatus(productBundle,index)"></span>
                                                </label>
                                            </div>
                                        </td>
                                        <!-- active/disabled block ends here-->

                                        <!-- action block starts here -->
                                        <td @click="previewBundle(productBundle,index,'input',$event)">
                                            <div class="ctmDataTableActionBtn" v-if="isDeletingItem(productBundle.id) && deleteLoader" data-open="true">
                                                <loaderBtn />
                                            </div>
                                            <div class="ctmDataTableActionBtn" data-open="true" v-else>
                                                <button class="delFunction" 
                                                    @click.prevent="deleteBundleHandle(productBundle,index)" 
                                                    :disabled="tourIsActive" 
                                                    data-open="false"
                                                >
                                                    <i aria-hidden="true" class="fa fa-trash-o" data-open="false"></i>
                                                </button>
                                            </div>
                                        </td>
                                        <!-- action block ends here -->

                                    </tr>

                                    <!-- load more block starts here -->
                                    <tr v-if="isPending">
                                        <td>
                                            <div class="ctmCheckWrap">
                                                <vue-skeleton-loader
                                                    type="rect"
                                                    :width="20"
                                                    :height="20"
                                                    animation="fade"
                                                />
                                            </div>
                                        </td>
                                        <td>
                                            <div class="ctmCheckWrap">
                                                <div class="ctmDataTableImgBox">
                                                    <vue-skeleton-loader
                                                        type="circle"
                                                        :width="36"
                                                        :height="36"
                                                        animation="fade"
                                                    />
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="ctmCheckWrap">
                                                <div class="ctmDataTableImgBox">
                                                    <vue-skeleton-loader
                                                        type="circle"
                                                        :width="36"
                                                        :height="36"
                                                        animation="fade"
                                                    />
                                                </div>
                                            </div>
                                        </td>
                                        <td class="mainTableTitle">
                                            <div class="editFieldTitle">
                                                <vue-skeleton-loader
                                                    type="rect"
                                                    :width="150"
                                                    :height="20"
                                                    animation="fade"
                                                />
                                            </div>
                                        </td>
                                        <td>
                                            <div class="tableBodyCtmTxt">
                                                <vue-skeleton-loader
                                                    type="rect"
                                                    :width="250"
                                                    :height="20"
                                                    animation="fade"
                                                />
                                            </div>
                                        </td>
                                        <td>
                                            <div class="tableBodyCtmTxt">
                                                <vue-skeleton-loader
                                                    type="rect"
                                                    :width="150"
                                                    :height="20"
                                                    animation="fade"
                                                />
                                            </div>
                                        </td>
                                        <td>
                                            <div class="tableBodyCtmTxt">
                                                <vue-skeleton-loader
                                                    type="rect"
                                                    :width="150"
                                                    :height="20"
                                                    animation="fade"
                                                />
                                            </div>
                                        </td>
                                        <td>
                                            <div class="tableBodyCtmTxt">
                                                <vue-skeleton-loader
                                                    type="rect"
                                                    :width="100"
                                                    :height="20"
                                                    animation="fade"
                                                />
                                            </div>
                                        </td>
                                        <td>
                                            <vue-skeleton-loader
                                                type="rect"
                                                :width="100"
                                                :height="20"
                                                animation="fade"
                                            />
                                        </td>
                                    </tr>
                                    <!-- load more block ends here -->

                                </tbody>
                            </table>
                        </div>

                        <!-- add/update product bundle form starts here -->
                        <div class="productSlideBox" ref="viewInputForm" :class="previewForm.id ? 'activeRow': ''">
                            <div class="tableActionBtns" id="product-bundle-details-preview">
                                <div class="productTableSideBar">
                                    <div class="row">
                                        <div class="col-md-8" id="bundle-name-field-2">
                                            <div class="viewOrderTitle">
                                                <input 
                                                    type="text" 
                                                    placeholder="Write a product bundle name" 
                                                    v-model="previewForm.name" 
                                                    @input="slugGeneration('name')"
                                                    :disabled="tourIsActive"
                                                >
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="orderViewNav">
                                                <ul>
                                                    <el-tooltip class="box-item" effect="dark" content="Expand" placement="top-start">
                                                        <li class="sm-extand-hide">
                                                            <button @click.prevent="expand" :disabled="tourIsActive" id="expand-form">
                                                                <svg viewBox="0 0 21 21" xmlns="http://www.w3.org/2000/svg">
                                                                    <g fill="none" fill-rule="evenodd" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" transform="translate(2 2)">
                                                                        <path d="m16.5 5.5v-4.978l-5.5.014"/>
                                                                        <path d="m16.5.522-6 5.907"/>
                                                                        <path d="m11 16.521 5.5.002-.013-5.5"/>
                                                                        <path d="m16.5 16.429-6-5.907"/>
                                                                        <path d="m.5 5.5v-5h5.5"/>
                                                                        <path d="m6.5 6.429-6-5.907"/>
                                                                        <path d="m6 16.516-5.5.007v-5.023"/>
                                                                        <path d="m6.5 10.5-6 6"/>
                                                                    </g>
                                                                </svg>
                                                            </button>
                                                        </li>
                                                    </el-tooltip>
                                                    <el-tooltip class="box-item" effect="dark" content="Close" placement="top-start">
                                                        <li>
                                                            <button @click.prevent="closePreview" id="closeSide" :disabled="tourIsActive">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="29.446" height="33.409" viewBox="0 0 29.446 33.409">
                                                                    <g id="Group_20906" data-name="Group 20906" transform="translate(362.041 -2185.5)">
                                                                        <path id="Path_57522" data-name="Path 57522" d="M-361.041,2186.648v31.409" transform="translate(0 -0.148)" fill="none" stroke="#000" stroke-linecap="round" stroke-width="2"/>
                                                                        <g id="Group_20905" data-name="Group 20905" transform="translate(0 -0.5)">
                                                                            <path id="Path_57523" data-name="Path 57523" d="M-354.018,2202.5h20.265" transform="translate(0 0)" fill="none" stroke="#000" stroke-linecap="round" stroke-width="2"/>
                                                                            <path id="Path_57524" data-name="Path 57524" d="M-342.6,2193.747l8,9.15-8,8.85" transform="translate(1 -0.247)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                                                                        </g>
                                                                    </g>
                                                                </svg>
                                                            </button>
                                                        </li>
                                                    </el-tooltip>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="sectionTabsLink">
                                    <ul onclick="window.myFunction(event)">
                                        <li v-if="!tourIsActive">
                                            <a href="#bundle-details-section" id="bundle-details-navbtn" class="active">Bundle Info</a>
                                        </li>
                                        <li v-if="!tourIsActive">
                                            <a href="#bundle-seo-section" id="bundle-seo-navbtn">SEO Essentials</a>
                                        </li>
                                    </ul>
                                    <div class="updateBtnBox">
                                        <loaderBtn v-if="productBundleIsSaving"/>
                                        <button 
                                            id="product-bundle-save-btn" 
                                            form="product-bundle-information"
                                            type="submit"
                                            :disabled="tourIsActive" 
                                            v-else
                                        >
                                            {{ previewForm.id == -1 ? 'Save' : 'Update' }}
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <ValidationObserver ref="productBundlesFormObserver">
                                <div class="productTableInfoBlock">

                                    <!-- bundle details section starts here -->
                                    <div class="productTableInfoWhiteBox" id="bundle-details-section">
                                        <form id="product-bundle-information" @submit.prevent="validate()">
                                            
                                            <!-- bundle name field starts here -->
                                            <div class="tableFromFieldBox">
                                                <label for="bundle-name-field-1">Name<span>*</span></label>
                                                <ValidationProvider
                                                    name="name" 
                                                    rules="required|max:250" 
                                                    v-slot="{ errors }"
                                                >
                                                    <div class="tableFromFieldItem">
                                                        <input 
                                                            type="text" 
                                                            id="bundle-name-field-1" 
                                                            name="bundle-name-field-1" 
                                                            class="form-control" 
                                                            placeholder="Name" 
                                                            v-model="previewForm.name"
                                                            @input="slugGeneration('name')"
                                                            :disabled="tourIsActive"
                                                        />
                                                    </div>
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </ValidationProvider>
                                            </div>
                                            <!-- bundle name field ends here -->

                                            <!-- bundle slug field starts here -->
                                            <div class="tableFromFieldBox">
                                                <label for="bundle-slug-field">Slug<span>*</span></label>
                                                <ValidationProvider
                                                    name="slug" 
                                                    rules="required|max:250" 
                                                    v-slot="{ errors }"
                                                >
                                                    <div class="tableFromFieldItem">
                                                        <input 
                                                            type="text" 
                                                            id="bundle-slug-field" 
                                                            name="bundle-slug-field" 
                                                            class="form-control" 
                                                            placeholder="Slug" 
                                                            v-model="previewForm.slug"
                                                            @blur="slugGeneration('slug')"
                                                            :disabled="tourIsActive"
                                                        />
                                                    </div>
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </ValidationProvider>
                                            </div>
                                            <!-- bundle slug field ends here -->

                                            <!-- bundle description field starts here -->
                                            <div class="tableFromFieldBox" id="product-bundle-description">
                                                <ValidationProvider name="description" rules="required|max:500" v-slot="{ errors }">
                                                    <label for="bundle-description-field">Description<span>*</span></label>
                                                    <div class="tableFromFieldItem">
                                                        <vue-editor
                                                            id="bundle-description-field"
                                                            name="bundle-description-field"
                                                            useCustomImageHandler
                                                            @image-added="contentImageHandler"
                                                            :editorOptions="editorOptions"
                                                            v-model="previewForm.description"
                                                            :disabled="tourIsActive"
                                                        ></vue-editor>
                                                    </div>
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </ValidationProvider>
                                            </div>
                                            <!-- bundle description field ends here -->
                                           
                                            <!-- bundle images field starts here -->
                                            <div class="tableFromFieldBox" id="bundle-images-section" v-if="previewForm.id == -1">
                                                <label>Images<span>*</span>
                                                    <p class="fieldSeoMessage">
                                                        <span style="display: inline-flex;margin-right: 5px;">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="13.75" height="13.75" viewBox="0 0 13.75 13.75" fill="#9e9b9b">
                                                                <path id="exclamationsign" d="M6.875,13.75A6.853,6.853,0,0,1,.92,10.326,6.749,6.749,0,0,1,0,6.875,6.853,6.853,0,0,1,3.424.92,6.756,6.756,0,0,1,6.875,0,6.853,6.853,0,0,1,12.83,3.424a6.759,6.759,0,0,1,.92,3.451,6.853,6.853,0,0,1-3.424,5.955A6.753,6.753,0,0,1,6.875,13.75Zm0-2.578a.831.831,0,0,0,.611-.248.82.82,0,0,0,.248-.6.836.836,0,0,0-.255-.611.843.843,0,0,0-1.208,0,.836.836,0,0,0-.255.611.82.82,0,0,0,.248.6A.831.831,0,0,0,6.875,11.171Zm0-8.593A1.719,1.719,0,0,0,5.156,4.3l.859,3.437a.82.82,0,0,0,.255.611.859.859,0,0,0,1.208,0,.818.818,0,0,0,.255-.611L8.593,4.3A1.719,1.719,0,0,0,6.875,2.578Z"/>
                                                            </svg> 
                                                        </span>
                                                        Arrange Images Your Way: Simply Drag and Drop! (First image will be considered as featured image)
                                                    </p>
                                                </label>
                                                <ValidationProvider name="images" :rules="previewForm.files.length == 0 ? 'required' :''" v-slot="{ errors }">
                                                    <div class="singleProductImgs">
                                                        <draggable-image-uploader
                                                            :draggableValues="previewForm.files"
                                                            :filesType="'images'"
                                                            :setFiles="imageFilesHandler"
                                                            @preview-event="handleImagePreview"
                                                            :moduleType="'product-bundles'"
                                                            :isDisabled="tourIsActive"
                                                        />
                                                    </div>
                                                    <span class="text-danger" v-if="previewForm.files.length == 0">{{ errors[0] }}</span>
                                                </ValidationProvider>
                                            </div>
                                            <div class="tableFromFieldBox" id="bundle-images-section" v-else>
                                                <label>Images<span>*</span>
                                                    <p class="fieldSeoMessage">
                                                        <span style="display: inline-flex;margin-right: 5px;">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="13.75" height="13.75" viewBox="0 0 13.75 13.75" fill="#9e9b9b">
                                                                <path id="exclamationsign" d="M6.875,13.75A6.853,6.853,0,0,1,.92,10.326,6.749,6.749,0,0,1,0,6.875,6.853,6.853,0,0,1,3.424.92,6.756,6.756,0,0,1,6.875,0,6.853,6.853,0,0,1,12.83,3.424a6.759,6.759,0,0,1,.92,3.451,6.853,6.853,0,0,1-3.424,5.955A6.753,6.753,0,0,1,6.875,13.75Zm0-2.578a.831.831,0,0,0,.611-.248.82.82,0,0,0,.248-.6.836.836,0,0,0-.255-.611.843.843,0,0,0-1.208,0,.836.836,0,0,0-.255.611.82.82,0,0,0,.248.6A.831.831,0,0,0,6.875,11.171Zm0-8.593A1.719,1.719,0,0,0,5.156,4.3l.859,3.437a.82.82,0,0,0,.255.611.859.859,0,0,0,1.208,0,.818.818,0,0,0,.255-.611L8.593,4.3A1.719,1.719,0,0,0,6.875,2.578Z"/>
                                                            </svg> 
                                                        </span>
                                                        Arrange Images Your Way: Simply Drag and Drop! (First image will be considered as featured image)
                                                    </p>
                                                </label>
                                                <div class="singleProductImgs" v-if="previewForm.id != ''">
                                                    <images-upload-draggable
                                                        :draggableValues="previewForm.defaultFiles"
                                                        :defaultFiles="previewForm.defaultFiles"
                                                        :setFiles="imageFilesHandler"
                                                        :filesType="'images'"
                                                        :selectedProductId="previewForm.id"
                                                        :onReorder="reorderProductImages"
                                                        :onRemove="removeProductImages"
                                                        @preview-event="handleImagePreview"
                                                        :moduleType="'product-bundles'"
                                                    />
                                                    <p class="fieldSeoMessage">
                                                        <span style="display: inline-flex;margin-right: 5px;">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="13.75" height="13.75" viewBox="0 0 13.75 13.75" fill="#9e9b9b">
                                                                <path id="exclamationsign" d="M6.875,13.75A6.853,6.853,0,0,1,.92,10.326,6.749,6.749,0,0,1,0,6.875,6.853,6.853,0,0,1,3.424.92,6.756,6.756,0,0,1,6.875,0,6.853,6.853,0,0,1,12.83,3.424a6.759,6.759,0,0,1,.92,3.451,6.853,6.853,0,0,1-3.424,5.955A6.753,6.753,0,0,1,6.875,13.75Zm0-2.578a.831.831,0,0,0,.611-.248.82.82,0,0,0,.248-.6.836.836,0,0,0-.255-.611.843.843,0,0,0-1.208,0,.836.836,0,0,0-.255.611.82.82,0,0,0,.248.6A.831.831,0,0,0,6.875,11.171Zm0-8.593A1.719,1.719,0,0,0,5.156,4.3l.859,3.437a.82.82,0,0,0,.255.611.859.859,0,0,0,1.208,0,.818.818,0,0,0,.255-.611L8.593,4.3A1.719,1.719,0,0,0,6.875,2.578Z"/>
                                                            </svg> 
                                                        </span>
                                                        You don't have to click the update button after modifying product bundle images. Simply make the change and close the form – it will be automatically saved.
                                                    </p>
                                                </div>
                                            </div>
                                            <!-- bundle images field ends here -->

                                            <!-- bundle videos field starts here -->
                                            <div class="tableFromFieldBox" id="bundle-videos-section" v-if="previewForm.id == -1">
                                                <label>Videos
                                                    <p class="fieldSeoMessage">
                                                        <span style="display: inline-flex;margin-right: 5px;">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="13.75" height="13.75" viewBox="0 0 13.75 13.75" fill="#9e9b9b">
                                                                <path id="exclamationsign" d="M6.875,13.75A6.853,6.853,0,0,1,.92,10.326,6.749,6.749,0,0,1,0,6.875,6.853,6.853,0,0,1,3.424.92,6.756,6.756,0,0,1,6.875,0,6.853,6.853,0,0,1,12.83,3.424a6.759,6.759,0,0,1,.92,3.451,6.853,6.853,0,0,1-3.424,5.955A6.753,6.753,0,0,1,6.875,13.75Zm0-2.578a.831.831,0,0,0,.611-.248.82.82,0,0,0,.248-.6.836.836,0,0,0-.255-.611.843.843,0,0,0-1.208,0,.836.836,0,0,0-.255.611.82.82,0,0,0,.248.6A.831.831,0,0,0,6.875,11.171Zm0-8.593A1.719,1.719,0,0,0,5.156,4.3l.859,3.437a.82.82,0,0,0,.255.611.859.859,0,0,0,1.208,0,.818.818,0,0,0,.255-.611L8.593,4.3A1.719,1.719,0,0,0,6.875,2.578Z"/>
                                                            </svg> 
                                                        </span>
                                                        Arrange Videos Your Way: Simply Drag and Drop!
                                                    </p>
                                                </label>
                                                <div class="singleProductImgs">
                                                    <draggable-video-uploader 
                                                        :draggableValues="previewForm.videos"
                                                        :filesType="'videos'"
                                                        :setFiles="videoFilesHandler"
                                                        @preview-event="handleVideoPreview"
                                                        :moduleType="'product-bundles'"
                                                        :isDisabled="tourIsActive"
                                                    />
                                                </div>
                                            </div>
                                            <div class="tableFromFieldBox" id="bundle-videos-section" v-else>
                                                <label>Videos
                                                    <p class="fieldSeoMessage">
                                                        <span style="display: inline-flex;margin-right: 5px;">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="13.75" height="13.75" viewBox="0 0 13.75 13.75" fill="#9e9b9b">
                                                                <path id="exclamationsign" d="M6.875,13.75A6.853,6.853,0,0,1,.92,10.326,6.749,6.749,0,0,1,0,6.875,6.853,6.853,0,0,1,3.424.92,6.756,6.756,0,0,1,6.875,0,6.853,6.853,0,0,1,12.83,3.424a6.759,6.759,0,0,1,.92,3.451,6.853,6.853,0,0,1-3.424,5.955A6.753,6.753,0,0,1,6.875,13.75Zm0-2.578a.831.831,0,0,0,.611-.248.82.82,0,0,0,.248-.6.836.836,0,0,0-.255-.611.843.843,0,0,0-1.208,0,.836.836,0,0,0-.255.611.82.82,0,0,0,.248.6A.831.831,0,0,0,6.875,11.171Zm0-8.593A1.719,1.719,0,0,0,5.156,4.3l.859,3.437a.82.82,0,0,0,.255.611.859.859,0,0,0,1.208,0,.818.818,0,0,0,.255-.611L8.593,4.3A1.719,1.719,0,0,0,6.875,2.578Z"/>
                                                            </svg> 
                                                        </span>
                                                        Arrange Videos Your Way: Simply Drag and Drop!
                                                    </p>
                                                </label>
                                                <div class="singleProductImgs" v-if="previewForm.id != ''">
                                                    <videos-upload-draggable 
                                                        :draggableValues="previewForm.defaultVideoFiles"
                                                        :defaultFiles="previewForm.defaultVideoFiles"
                                                        :setFiles="videoFilesHandler"
                                                        :filesType="'videos'"
                                                        :selectedProductId="previewForm.id"
                                                        :onReorder="reorderProductVideos"
                                                        :onRemove="removeProductVideos"
                                                        @preview-event="handleVideoPreview"
                                                        :moduleType="'product-bundles'"
                                                    />
                                                    <p class="fieldSeoMessage">
                                                        <span style="display: inline-flex;margin-right: 5px;">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="13.75" height="13.75" viewBox="0 0 13.75 13.75" fill="#9e9b9b">
                                                                <path id="exclamationsign" d="M6.875,13.75A6.853,6.853,0,0,1,.92,10.326,6.749,6.749,0,0,1,0,6.875,6.853,6.853,0,0,1,3.424.92,6.756,6.756,0,0,1,6.875,0,6.853,6.853,0,0,1,12.83,3.424a6.759,6.759,0,0,1,.92,3.451,6.853,6.853,0,0,1-3.424,5.955A6.753,6.753,0,0,1,6.875,13.75Zm0-2.578a.831.831,0,0,0,.611-.248.82.82,0,0,0,.248-.6.836.836,0,0,0-.255-.611.843.843,0,0,0-1.208,0,.836.836,0,0,0-.255.611.82.82,0,0,0,.248.6A.831.831,0,0,0,6.875,11.171Zm0-8.593A1.719,1.719,0,0,0,5.156,4.3l.859,3.437a.82.82,0,0,0,.255.611.859.859,0,0,0,1.208,0,.818.818,0,0,0,.255-.611L8.593,4.3A1.719,1.719,0,0,0,6.875,2.578Z" />
                                                            </svg> 
                                                        </span>
                                                        You don't have to click the update button after modifying product bundle videos. Simply make the change and close the form – it will be automatically saved.
                                                    </p>
                                                </div>
                                            </div>
                                            <!-- bundle videos field ends here -->

                                        </form>

                                        <!-- bundle tags field starts here -->
                                        <div class="tableFromFieldBox">
                                            <label for="tags">Tags  <span>*</span></label>
                                            <div class="vueTagSelectionBox" id="product-bundle-tags">
                                                <Tag :value="previewForm.tags" field="tags" placeHolder="Add tags" :disableField="tourIsActive"/>
                                                <p class="fieldSeoMessage">
                                                    <span style="display: inline-flex;margin-right: 5px;">
                                                        <svg  xmlns="http://www.w3.org/2000/svg" width="13.75" height="13.75" viewBox="0 0 13.75 13.75" fill="#9e9b9b">
                                                            <path id="exclamationsign" d="M6.875,13.75A6.853,6.853,0,0,1,.92,10.326,6.749,6.749,0,0,1,0,6.875,6.853,6.853,0,0,1,3.424.92,6.756,6.756,0,0,1,6.875,0,6.853,6.853,0,0,1,12.83,3.424a6.759,6.759,0,0,1,.92,3.451,6.853,6.853,0,0,1-3.424,5.955A6.753,6.753,0,0,1,6.875,13.75Zm0-2.578a.831.831,0,0,0,.611-.248.82.82,0,0,0,.248-.6.836.836,0,0,0-.255-.611.843.843,0,0,0-1.208,0,.836.836,0,0,0-.255.611.82.82,0,0,0,.248.6A.831.831,0,0,0,6.875,11.171Zm0-8.593A1.719,1.719,0,0,0,5.156,4.3l.859,3.437a.82.82,0,0,0,.255.611.859.859,0,0,0,1.208,0,.818.818,0,0,0,.255-.611L8.593,4.3A1.719,1.719,0,0,0,6.875,2.578Z"/>
                                                        </svg> 
                                                    </span>
                                                    Press the "Enter" key after adding a tag in the field
                                                </p>
                                            </div>
                                            <div class="row align-items-center">
                                                <div class="col-md-6">
                                                    <div class="ctmFeaturedCheckBox" id="product-bundle-is-featured">
                                                        <label class="ctmFeaturedContainer">Featured
                                                            <input type="checkbox" v-model="previewForm.isFeatured" :disabled="tourIsActive"/>
                                                            <span class="checkmark"></span>
                                                        </label>
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="resetTagBtn">
                                                        <button 
                                                            type="button"
                                                            @click.prevent="resetProductTags()" 
                                                            :disabled="tourIsActive"
                                                            id="product-bundle-tags-reset-btn"
                                                        >
                                                            Reset
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- bundle tags field starts here -->

                                    </div>
                                    <!-- bundle details section ends here -->

                                    <!-- bundle products section starts here -->
                                    <div class="productTableInfoWhiteBox" id="bundle-product-section">
                                        
                                        <div class="productTableInfoWhiteBoxTitle" id="products-section">
                                            <h5>Products</h5>
                                        </div>

                                        <div class="addOrderTable" id="update-product-section">
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th>Image</th>
                                                        <th>Name</th>
                                                        <th>Type</th>
                                                        <th>Unit Price</th>
                                                        <th>Quantity</th>
                                                        <th>Total</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-if="previewForm.bundle_items.length == 0">
                                                        <td colspan="7">
                                                            The bundle has no items at the moment
                                                        </td>
                                                    </tr>
                                                    <tr v-for="(item, index) in previewForm.bundle_items" :key="index">
                                                        <td>
                                                            <div class="productOrderTableImg">
                                                                <img 
                                                                    :src="item.item_details.image ?  base_image_url + settings.store_id + '/product/' + item.item_details.image : '/images/no_image.png'"
                                                                    @error="handleImageError"
                                                                />
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div class="productOrderTableTxt">
                                                                <p>{{ item.item_details.name.length > 15 ? item.item_details.name.substr(0,15) + "..." : item.item_details.name }}</p>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div class="productOrderTableTagTxt">
                                                                <p>{{ item.item_details.type }}</p>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div class="productOrderTableTxt">
                                                                <p>
                                                                    {{
                                                                        settings.currency_symbol + " " + item.item_details.variant.price.toLocaleString("ur-PK", {
                                                                            minimumFractionDigits: 2,
                                                                            maximumFractionDigits: 2,
                                                                        })
                                                                    }}
                                                                </p>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div class="quantityOrderProductBox">
                                                                <button
                                                                    class="miniusIconBtn"
                                                                    @click.prevent="bundleItemQuantityChangeHandle('decr',index)"
                                                                    :disabled="tourIsActive"
                                                                >
                                                                    <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9.86 2.3">
                                                                        <g id="Layer_1-2" data-name="Layer 1">
                                                                            <g id="_-" data-name=" -" class="cls-1">
                                                                                <g class="cls-1">
                                                                                    <path class="cls-2" d="m9.86,0v2.3H0V0h9.86Z"/>
                                                                                </g>
                                                                            </g>
                                                                        </g>
                                                                    </svg>
                                                                </button>
                                                                <input
                                                                    type="number"
                                                                    name="stock"
                                                                    id="stock"
                                                                    placeholder="0"
                                                                    v-model="item.min_quantity"
                                                                    @blur="productQuantityChangeCheck(index)"
                                                                    :disabled="tourIsActive"
                                                                />
                                                                <button
                                                                    class="plusIconBtn"
                                                                    @click.prevent="bundleItemQuantityChangeHandle('incr',index)"
                                                                    :disabled="tourIsActive"
                                                                >
                                                                    <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.24 12.24">
                                                                        <g id="Layer_1-2" data-name="Layer 1">
                                                                            <g id="_" data-name=" " class="cls-1">
                                                                                <g class="cls-1">
                                                                                    <path class="cls-2" d="m12.24,7.27h-4.85v4.97h-2.54v-4.97H0v-2.3h4.85V0h2.54v4.97h4.85v2.3Z"/>
                                                                                </g>
                                                                            </g>
                                                                        </g>
                                                                    </svg>
                                                                </button>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div class="productOrderTableTxt">
                                                                <p>
                                                                    {{
                                                                        calculateProductTotal(item) ? settings.currency_symbol + " " + calculateProductTotal(item,'add-order').toLocaleString("ur-PK", {
                                                                            minimumFractionDigits: 2,
                                                                            maximumFractionDigits: 2,
                                                                        }) : ''
                                                                    }}
                                                                </p>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div class="ctmDataTableActionBtn">
                                                                <button
                                                                    class="delFunction"
                                                                    @click.prevent="removeBundleItem(item, 'bundle-form', index)"
                                                                    :disabled="tourIsActive"
                                                                >
                                                                    <i aria-hidden="true" class="fa fa-trash-o"></i>
                                                                </button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div class="orderAddProductBtn">
                                            <button 
                                                @click.prevent="productsPopup.isOpen = true"
                                                :disabled="tourIsActive"
                                                id="add-product-btn"
                                            >
                                                + Add Product
                                            </button>
                                        </div>
                                    </div>
                                    <!-- bundle products section ends here -->

                                    <!-- bundle discount section starts here -->
                                    <div class="productTableInfoWhiteBox" id="bundle-discount-section">
                                        <div class="productTableInfoWhiteBoxTitle">
                                            <h5>Bundle Discount</h5>
                                        </div>
                                        <div class="tableFromFieldBox">
                                            <label for="max-per-user-usage">Discount Type<span>*</span></label>
                                            <ValidationProvider
                                                name="discount type" 
                                                rules="required" 
                                                v-slot="{ errors }"
                                            >
                                                <div class="vueCtmSelectBlock">
                                                    <select 
                                                        class="form-select" 
                                                        aria-label="Default select example" 
                                                        v-model="previewForm.discount_type"
                                                        id="discount-type-field"
                                                        :disabled="tourIsActive"
                                                    >
                                                        <option value="none">None</option>
                                                        <option selected value="percentage">Percentage</option>
                                                        <option value="flat">Flat</option>
                                                    </select>
                                                </div>
                                                <span class="text-danger">{{ errors[0] }}</span>
                                            </ValidationProvider>
                                        </div>
                                        <div class="tableFromFieldBox" v-if="previewForm.discount_type != 'none'">
                                            <label for="discount-value-field">Discount Value<span>*</span></label>
                                            <ValidationProvider 
                                                name="discount value" 
                                                :rules="previewForm.discount_type == 'percentage' ? 
                                                { required:true,max_value:99,min_value:1,regex: '^[0-9][0-9]*$' } : 
                                                { required:true,max_value:previewForm.bundle_total,min_value:1,regex: '^[0-9][0-9]*$' }" 
                                                v-slot="{ errors }"
                                            >
                                                <div class="tableFromFieldItem">
                                                    <input 
                                                        type="text" 
                                                        id="discount-value-field"
                                                        name="discount-value-field" 
                                                        class="form-control"
                                                        placeholder="Discount value"
                                                        v-model="previewForm.discount_value" 
                                                        :disabled="tourIsActive"
                                                    />
                                                </div>
                                                <span class="text-danger">{{ errors[0] }}</span>
                                            </ValidationProvider>
                                        </div>

                                        <!-- bundle total section starts here -->
                                        <div class="priceDetailList" id="bundle-total-section">
                                            <ul>
                                                <li>
                                                </li>
                                                <li>
                                                    <h4>Bundle Total</h4>
                                                    <h2>
                                                        {{  settings.currency_unit ? settings.currency_symbol + " " +
                                                                parseFloat(calculcateBundleTotal).toLocaleString("ur-PK", {
                                                                    minimumFractionDigits: 2,
                                                                    maximumFractionDigits: 2,
                                                                }) 
                                                            : ''
                                                        }}
                                                    </h2>
                                                </li>
                                                <li v-if="this.previewForm.discount_type != 'none'">
                                                    <h4>Discounted Bundle Total</h4>
                                                    <h2>
                                                        {{  settings.currency_unit ? settings.currency_symbol + " " +
                                                                parseFloat(previewForm.discounted_total).toLocaleString("ur-PK", {
                                                                    minimumFractionDigits: 2,
                                                                    maximumFractionDigits: 2,
                                                                }) 
                                                            : ''
                                                        }}
                                                    </h2>
                                                </li>
                                            </ul>
                                        </div>
                                        <!-- bundle total section ends here -->

                                    </div>
                                    <!-- bundle discount section ends here -->

                                    <!-- bundle seo section starts here -->
                                    <div class="productTableInfoWhiteBox" id="bundle-seo-section">
                                        <div class="productTableInfoWhiteBoxTitle">
                                            <h5>SEO</h5>
                                        </div>
                                        
                                        <!-- bundle keywords field starts here -->
                                        <div class="tableFromFieldBox" id="product-bundle-keywords">
                                            <label for="keyword">Keywords<span>*</span></label>
                                            <div class="vueTagSelectionBox">
                                                <Tag :value="previewForm.keywords" field="keywords" :placeHolder="`Add keywords`" :disableField="tourIsActive"/>
                                                <p class="fieldSeoMessage">
                                                    <span style="display: inline-flex;margin-right: 5px;">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="13.75" height="13.75" viewBox="0 0 13.75 13.75" fill="#9e9b9b">
                                                            <path id="exclamationsign" d="M6.875,13.75A6.853,6.853,0,0,1,.92,10.326,6.749,6.749,0,0,1,0,6.875,6.853,6.853,0,0,1,3.424.92,6.756,6.756,0,0,1,6.875,0,6.853,6.853,0,0,1,12.83,3.424a6.759,6.759,0,0,1,.92,3.451,6.853,6.853,0,0,1-3.424,5.955A6.753,6.753,0,0,1,6.875,13.75Zm0-2.578a.831.831,0,0,0,.611-.248.82.82,0,0,0,.248-.6.836.836,0,0,0-.255-.611.843.843,0,0,0-1.208,0,.836.836,0,0,0-.255.611.82.82,0,0,0,.248.6A.831.831,0,0,0,6.875,11.171Zm0-8.593A1.719,1.719,0,0,0,5.156,4.3l.859,3.437a.82.82,0,0,0,.255.611.859.859,0,0,0,1.208,0,.818.818,0,0,0,.255-.611L8.593,4.3A1.719,1.719,0,0,0,6.875,2.578Z"/>
                                                        </svg> 
                                                    </span>
                                                    Press the "Enter" key after adding a keyword in the field
                                                </p>
                                            </div>
                                        </div>
                                        <!-- bundle keywords field ends here -->

                                        <!-- bundle meta fields section starts here -->
                                        <div class="tableFromFieldBox">
                                            <ValidationProvider name="meta title" :rules="{max:250}" v-slot="{ errors }">
                                                <label for="meta-title-field">Meta Title</label>
                                                <div class="tableFromFieldItem">
                                                    <input
                                                        type="text"
                                                        name="meta-title-field"
                                                        id="meta-title-field"
                                                        placeholder="Meta title"
                                                        form="product-bundle-information"
                                                        v-model="previewForm.meta_title"
                                                        :disabled="tourIsActive"
                                                    />
                                                    <p class="fieldSeoMessage" v-if="previewForm.meta_title && previewForm.meta_title.length <= 60">
                                                        <span style="display: inline-flex;margin-right: 5px;">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="13.75" height="13.75" viewBox="0 0 13.75 13.75" fill="#9e9b9b">
                                                                <path id="exclamationsign" d="M6.875,13.75A6.853,6.853,0,0,1,.92,10.326,6.749,6.749,0,0,1,0,6.875,6.853,6.853,0,0,1,3.424.92,6.756,6.756,0,0,1,6.875,0,6.853,6.853,0,0,1,12.83,3.424a6.759,6.759,0,0,1,.92,3.451,6.853,6.853,0,0,1-3.424,5.955A6.753,6.753,0,0,1,6.875,13.75Zm0-2.578a.831.831,0,0,0,.611-.248.82.82,0,0,0,.248-.6.836.836,0,0,0-.255-.611.843.843,0,0,0-1.208,0,.836.836,0,0,0-.255.611.82.82,0,0,0,.248.6A.831.831,0,0,0,6.875,11.171Zm0-8.593A1.719,1.719,0,0,0,5.156,4.3l.859,3.437a.82.82,0,0,0,.255.611.859.859,0,0,0,1.208,0,.818.818,0,0,0,.255-.611L8.593,4.3A1.719,1.719,0,0,0,6.875,2.578Z"/>
                                                            </svg> 
                                                        </span>
                                                        Do not exceed 60 characters when entering the product meta title for SEO
                                                    </p>
                                                    <p class="fieldSeoMessage" v-else-if="previewForm.meta_title && previewForm.meta_title.length > 60">
                                                        <span style="display: inline-flex;margin-right: 5px;">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="13.75" height="13.75" viewBox="0 0 13.75 13.75" fill="#9e9b9b">
                                                                <path id="exclamationsign" d="M6.875,13.75A6.853,6.853,0,0,1,.92,10.326,6.749,6.749,0,0,1,0,6.875,6.853,6.853,0,0,1,3.424.92,6.756,6.756,0,0,1,6.875,0,6.853,6.853,0,0,1,12.83,3.424a6.759,6.759,0,0,1,.92,3.451,6.853,6.853,0,0,1-3.424,5.955A6.753,6.753,0,0,1,6.875,13.75Zm0-2.578a.831.831,0,0,0,.611-.248.82.82,0,0,0,.248-.6.836.836,0,0,0-.255-.611.843.843,0,0,0-1.208,0,.836.836,0,0,0-.255.611.82.82,0,0,0,.248.6A.831.831,0,0,0,6.875,11.171Zm0-8.593A1.719,1.719,0,0,0,5.156,4.3l.859,3.437a.82.82,0,0,0,.255.611.859.859,0,0,0,1.208,0,.818.818,0,0,0,.255-.611L8.593,4.3A1.719,1.719,0,0,0,6.875,2.578Z"/>
                                                            </svg> 
                                                        </span>
                                                        Not SEO friendly
                                                    </p>
                                                    <p class="remainingTxt" v-if="previewForm.meta_title">
                                                        <span>{{ previewForm.meta_title ? previewForm.meta_title.length <= 60 ? 60 - previewForm.meta_title.length : 0 : 60}}</span>
                                                        Remaining
                                                    </p>
                                                    <span class="text-danger" >{{ errors[0] }}</span>
                                                </div>
                                            </ValidationProvider>
                                        </div>
                                        <div class="tableFromFieldBox">
                                            <ValidationProvider name="meta description" rules="max:500" v-slot="{ errors }">
                                                <label for="meta-description-field">Meta Description</label>
                                                <div class="tableFromFieldItem">
                                                    <textarea
                                                        name="meta-description-field"
                                                        id="meta-description-field"
                                                        placeholder="Meta description"
                                                        form="product-bundle-information"
                                                        v-model="previewForm.meta_description"
                                                        :disabled="tourIsActive"
                                                    >
                                                    </textarea>
                                                    <p class="fieldSeoMessage" v-if="previewForm.meta_description && previewForm.meta_description.length <= 300">
                                                        <span style="display: inline-flex;margin-right: 5px;">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="13.75" height="13.75" viewBox="0 0 13.75 13.75" fill="#9e9b9b">
                                                                <path id="exclamationsign" d="M6.875,13.75A6.853,6.853,0,0,1,.92,10.326,6.749,6.749,0,0,1,0,6.875,6.853,6.853,0,0,1,3.424.92,6.756,6.756,0,0,1,6.875,0,6.853,6.853,0,0,1,12.83,3.424a6.759,6.759,0,0,1,.92,3.451,6.853,6.853,0,0,1-3.424,5.955A6.753,6.753,0,0,1,6.875,13.75Zm0-2.578a.831.831,0,0,0,.611-.248.82.82,0,0,0,.248-.6.836.836,0,0,0-.255-.611.843.843,0,0,0-1.208,0,.836.836,0,0,0-.255.611.82.82,0,0,0,.248.6A.831.831,0,0,0,6.875,11.171Zm0-8.593A1.719,1.719,0,0,0,5.156,4.3l.859,3.437a.82.82,0,0,0,.255.611.859.859,0,0,0,1.208,0,.818.818,0,0,0,.255-.611L8.593,4.3A1.719,1.719,0,0,0,6.875,2.578Z"/>
                                                            </svg> 
                                                        </span>
                                                        Do not exceed 300 characters when entering the product meta description for SEO
                                                    </p>
                                                    <p class="fieldSeoMessage" v-else-if="previewForm.meta_description && previewForm.meta_description.length > 300">
                                                        <span style="display: inline-flex;margin-right: 5px;">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="13.75" height="13.75" viewBox="0 0 13.75 13.75" fill="#9e9b9b">
                                                                <path id="exclamationsign" d="M6.875,13.75A6.853,6.853,0,0,1,.92,10.326,6.749,6.749,0,0,1,0,6.875,6.853,6.853,0,0,1,3.424.92,6.756,6.756,0,0,1,6.875,0,6.853,6.853,0,0,1,12.83,3.424a6.759,6.759,0,0,1,.92,3.451,6.853,6.853,0,0,1-3.424,5.955A6.753,6.753,0,0,1,6.875,13.75Zm0-2.578a.831.831,0,0,0,.611-.248.82.82,0,0,0,.248-.6.836.836,0,0,0-.255-.611.843.843,0,0,0-1.208,0,.836.836,0,0,0-.255.611.82.82,0,0,0,.248.6A.831.831,0,0,0,6.875,11.171Zm0-8.593A1.719,1.719,0,0,0,5.156,4.3l.859,3.437a.82.82,0,0,0,.255.611.859.859,0,0,0,1.208,0,.818.818,0,0,0,.255-.611L8.593,4.3A1.719,1.719,0,0,0,6.875,2.578Z"/>
                                                            </svg> 
                                                        </span>
                                                        Not SEO friendly
                                                    </p>
                                                    <p class="remainingTxt" v-if="previewForm.meta_description">
                                                        <span>{{ previewForm.meta_description ? previewForm.meta_description.length <= 300 ? 300 - previewForm.meta_description.length  : 0 : 300 }}</span>
                                                        Remaining
                                                    </p>
                                                    <span class="text-danger" >{{ errors[0] }}</span>
                                                </div>
                                            </ValidationProvider>
                                        </div>
                                        <!-- bundle meta fields section ends here -->

                                        <!-- status & indexed section starts here -->
                                        <div class="ctmFlexRow">
                                            <div class="statusColumnBox">
                                                <div class="tableFromFieldBox" id="product-bundle-status">
                                                    <label for="productStatus">Status</label>
                                                    <div class="actionSwitch">
                                                        <label class="switch">
                                                            <input
                                                                type="checkbox"
                                                                v-model="previewForm.isActive"
                                                                :disabled="tourIsActive"
                                                            />
                                                            <span class="slider"></span>
                                                            <p class="ctmSwitchLabel">{{ previewForm.isActive ? "Active" : "inActive" }}</p>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="statusColumnBox">
                                                <div class="tableFromFieldBox" id="product-bundle-indexed">
                                                    <label for="productStatus">Indexed</label>
                                                    <div class="actionSwitch">
                                                        <label class="switch">
                                                            <input
                                                                type="checkbox"
                                                                v-model="previewForm.isIndexed"
                                                                :disabled="tourIsActive"
                                                            />
                                                            <span class="slider"></span>
                                                            <p class="ctmSwitchLabel">{{ previewForm.isIndexed ? "Yes" : "No" }}</p>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- status & indexed section ends here -->

                                    </div>
                                    <!-- bundle seo section ends here -->

                                </div>
                            </ValidationObserver>
                        </div>
                        <!-- add/update product bundle form ends here -->

                    </div>
                    <!-- data table ends here -->

                    <!--bottom action bar starts here-->
                    <div class="row product-bottom-bar m-0" v-if="checkedItems.length > 1">
                        <div class="col-md-6 model-footer-main1">
                            <div class="bottom-bar">
                                <a href="" class="model-footer-1 mr-5" @click.prevent="toggleIsCheckedAll()" v-if="checkedItems.length != productBundlesList.length">
                                    <i class="fa fa-check-square-o" aria-hidden="true"></i>
                                    <p>SELECT ALL</p>
                                </a>
                                <a href="" class="model-footer-1" @click.prevent="clearSelection()">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.026 14.027">
                                        <path id="cross-small" d="M18.969,6.023h0a1.079,1.079,0,0,0-1.525,0L12.5,10.971,7.549,6.023a1.079,1.079,0,0,0-1.525,0h0a1.079,1.079,0,0,0,0,1.525L10.971,12.5,6.023,17.443a1.079,1.079,0,0,0,0,1.525h0a1.079,1.079,0,0,0,1.525,0L12.5,14.021l4.947,4.947a1.079,1.079,0,0,0,1.525,0h0a1.079,1.079,0,0,0,0-1.525L14.021,12.5l4.947-4.947A1.079,1.079,0,0,0,18.969,6.023Z" transform="translate(-5.483 -5.483)" fill="#fd4d5d" stroke="#fd4d5d" stroke-width="0.3"/>
                                    </svg>
                                    <p>CLEAR SELECTION</p>
                                </a>
                            </div>
                            <div class="model-footer-2">{{ checkedItems.length }}</div>
                        </div>
                        <div class="col-md-6 model-footer-main1 product-row-reverse">
                            <div class="bottom-bar">
                                <a href="" class="model-footer-1 mr-4" @click.prevent="bulkActionHandle('active')">
                                    <p>ACTIVE</p>
                                </a>
                                <a href="" class="model-footer-1 mr-4" @click.prevent="bulkActionHandle('disable')">
                                    <p>DISABLE</p>
                                </a>
                                <a href="" class="model-footer-1 ml-4" @click.prevent="bulkActionHandle('delete')">
                                    <i class="fa fa-trash-o" aria-hidden="true"></i>
                                    <p>DELETE</p>
                                </a>
                            </div>
                        </div>
                    </div>
                    <!--bottom action bar ends here-->


                    <!-- product list modal starts here -->
                    <div class="modalBg" :class="productsPopup.isOpen ? 'modal-active' : ''">
                        <div class="modalLayers" @click.prevent="loadAvailableProducts"></div>
                        <div class="modalBody">
                            <div class="addProductTitle">
                                <h3>ADD PRODUCTS</h3>
                            </div>
                            <div class="searchProductForm">
                                <input
                                    type="search"
                                    name="searchProduct"
                                    id="searchProduct"
                                    placeholder="Search product"
                                    v-model="searchedProduct"
                                    @input="searchProducts('search',1)"
                                />
                            </div>
                            <div class="productMainSelectBox">
                                <div class="showProductBox" v-if="productSearchLoader">
                                    <div class="productGrayBox" v-for="i in 3" :key="i">
                                        <vue-skeleton-loader 
                                            type="rect" 
                                            :width="620" 
                                            :height="125" 
                                            animation="fade"
                                        />
                                    </div>
                                </div>
                                <div class="showProductBox" v-else>
                                    <div class="productGrayBox" v-if="productsList.length == 0 && searchedProduct.length != 0" style="justify-content: center !important">
                                        <p style="color: #000">
                                            No Active Products Found Against "{{ searchedProduct }}"
                                        </p>
                                    </div>
                                    <div class="productGrayBox" v-if="productsList.length == 0 && searchedProduct == 0" style="justify-content: center !important">
                                        <p style="color: #000">
                                            No Active Products Available
                                        </p>
                                    </div>
                                    <div class="productGrayBox" v-for="product in productsList" :key="product.id">
                                        <div class="productGrayBoxImg">
                                            <img 
                                                :src="product.image ? base_image_url+ settings.store_id + '/product/' + product.image : '/images/no_image.png'" 
                                                alt=""
                                                @error="handleImageError"
                                            />
                                        </div>
                                        <div class="productGrayBoxdetail">
                                            <div class="productNameDetail">
                                                <div class="ProductNameTxt">
                                                    <p>{{ product.name }}</p>
                                                </div>
                                            </div>
                                            <div class="productSkuBlock">
                                                <div 
                                                    class="productSkuWhiteBox" 
                                                    :class="variant.qty <= 0 || !variant.isActive ? 'stockOutProduct' : '' " 
                                                    v-for="variant in product.variants" :key="variant.id" 
                                                    :data-toggle="variant.qty <= 0 ? 'tooltip' : '' " 
                                                    :data-placement="variant.qty <= 0 ? 'right' : '' " 
                                                    :title="variant.qty <= 0 ? 'Out of Stock' : !variant.isActive ? 'Variant Inactive'  : '' "
                                                >
                                                    <h5>{{ variant.name }}</h5>
                                                    <p>{{ variant.sku }}</p>
                                                    <p class="productPrice" v-if="variant.sale_price">
                                                        {{  variant.sale_price ? settings.currency_symbol + " " + parseFloat(variant.sale_price).toLocaleString(
                                                                    "ur-PK",
                                                                    {
                                                                        minimumFractionDigits: 2,
                                                                        maximumFractionDigits: 2,
                                                                    }
                                                                )
                                                            : 0 
                                                        }}
                                                    </p>
                                                    <p class="productPrice" v-else>
                                                        {{  variant.price ? settings.currency_symbol + " " + parseFloat(variant.price).toLocaleString(
                                                                    "ur-PK",
                                                                    {
                                                                        minimumFractionDigits: 2,
                                                                        maximumFractionDigits: 2,
                                                                    }
                                                                )
                                                            : 0 
                                                        }}
                                                    </p>
                                                    <button
                                                        @click="
                                                            addBundleItem({
                                                                product_id: product.id,
                                                                name: product.name,
                                                                type: product.product_type,
                                                                image: product.image,
                                                                variant: {
                                                                    id: variant.id,
                                                                    name: variant.name,
                                                                    qty: 1,
                                                                    stock: variant.qty,
                                                                    price: variant.price,
                                                                    weight: variant.weight,
                                                                    sku: variant.sku,
                                                                },
                                                            })
                                                        "
                                                        :disabled="variant.qty <= 0 || !variant.isActive"
                                                    >
                                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13.5 13.5">
                                                            <g id="plus" transform="translate(0)">
                                                                <path id="Path_52" data-name="Path 52" d="M14.625,7.875a.75.75,0,0,1,.75.75v6a.75.75,0,0,1-.75.75h-6a.75.75,0,0,1,0-1.5h5.25V8.625A.75.75,0,0,1,14.625,7.875Z" transform="translate(-7.875 -7.875)" fill-rule="evenodd"/>
                                                                <path id="Path_53" data-name="Path 53" d="M16.875,17.625a.75.75,0,0,1,.75-.75h6a.75.75,0,0,1,0,1.5h-5.25v5.25a.75.75,0,0,1-1.5,0Z" transform="translate(-10.875 -10.875)" fill-rule="evenodd"/>
                                                            </g>
                                                        </svg>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="addProductShowBox">
                                    <div class="addProductTitle">
                                        <h3>ADDED PRODUCTS</h3>
                                    </div>
                                    <div class="productDelList">
                                        <ul>
                                            <li v-for="(item, index) in previewForm.bundle_items" :key="index">
                                                <div class="productListDetail">
                                                    <div>
                                                        <h5>{{ item.item_details.name }}</h5>
                                                        <p>{{ item.item_details.variant.name }}</p>
                                                        <p>{{ item.item_details.variant.sku }}</p>
                                                    </div>
                                                    <p class="productListDetailPrice">
                                                        {{  item.item_details.variant.price ? settings.currency_symbol + " " + parseFloat(item.item_details.variant.price).toLocaleString(
                                                                "ur-PK",
                                                                {
                                                                    minimumFractionDigits: 2,
                                                                    maximumFractionDigits: 2,
                                                                }
                                                            )
                                                            : 0 
                                                        }}
                                                    </p>
                                                </div>
                                                <div class="delIconBox">
                                                    <button @click="removeBundleItem(item, 'product-modal', index)">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="25.667" viewBox="0 0 22 25.667">
                                                            <g id="Group_175" data-name="Group 175" transform="translate(-1781 -573.417)">
                                                                <path id="Path_68" data-name="Path 68" d="M13.5,13.5h1.833v11H13.5Z" transform="translate(1774.833 569.083)" fill="#fd4d5d"/>
                                                                <path id="Path_69" data-name="Path 69" d="M20.25,13.5h1.833v11H20.25Z" transform="translate(1773.583 569.083)" fill="#fd4d5d"/>
                                                                <path id="Path_70" data-name="Path 70" d="M4.5,6.75V8.583H6.333V26.917A1.833,1.833,0,0,0,8.167,28.75H22.833a1.833,1.833,0,0,0,1.833-1.833V8.583H26.5V6.75ZM8.167,26.917V8.583H22.833V26.917Z" transform="translate(1776.5 570.333)" fill="#fd4d5d"/>
                                                                <path id="Path_71" data-name="Path 71" d="M13.5,2.25h7.333V4.083H13.5Z" transform="translate(1774.833 571.167)" fill="#fd4d5d"/>
                                                            </g>
                                                        </svg>
                                                    </button>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <hr v-if="productsPagination.total > 10">

                            <el-pagination
                                background
                                layout="prev, pager, next"
                                :page-count="productsPagination.last_page"
                                @current-change="productPaginateChangeHandle"
                                class="mt-4"
                                v-if="productsPagination.total > 10"
                            />

                            <hr>

                            <div class="modalDoneButton">
                                <button @click.prevent="loadAvailableProducts">
                                    Done
                                </button>
                            </div>
                            <div class="modalCloseIcon">
                                <button @click.prevent="loadAvailableProducts">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22">
                                        <path id="close" d="M31,10.925,29.075,9,20,18.075,10.925,9,9,10.925,18.075,20,9,29.075,10.925,31,20,21.925,29.075,31,31,29.075,21.925,20Z" transform="translate(-9 -9)" fill="#22678d"/>
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div>
                    <!-- product list modal ends here -->

                </div>

                <!-- filter popup starts here -->
                <div class="filterModalWrap" v-if="filterPopup.isOpen">
                    <div class="filterWhiteBox" id="filter-modal">
                        <button class="closeFiltericon" @click.prevent="closeFilterPopup()">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.026 14.027">
                                <path id="cross-small" d="M18.969,6.023h0a1.079,1.079,0,0,0-1.525,0L12.5,10.971,7.549,6.023a1.079,1.079,0,0,0-1.525,0h0a1.079,1.079,0,0,0,0,1.525L10.971,12.5,6.023,17.443a1.079,1.079,0,0,0,0,1.525h0a1.079,1.079,0,0,0,1.525,0L12.5,14.021l4.947,4.947a1.079,1.079,0,0,0,1.525,0h0a1.079,1.079,0,0,0,0-1.525L14.021,12.5l4.947-4.947A1.079,1.079,0,0,0,18.969,6.023Z" transform="translate(-5.483 -5.483)" fill="#fd4d5d" stroke="#fd4d5d" stroke-width="0.3"/>
                            </svg>
                        </button>
                        <div class="filterModalTitle">
                            <h5>Product Bundle Filters</h5>
                        </div>
                        <div class="filterScrollProduct">
                            <div class="categoryFilterBtns">
                                <h6>TAGS</h6>
                                <ul v-if="filterTags.loading && filterTags.list.length == 0">
                                    <li v-for="i in 3" :key="'item_categories_' + i">
                                        <vue-skeleton-loader
                                            type="rect"
                                            :width="124"
                                            :height="35"
                                            animation="fade"
                                        />
                                    </li>
                                </ul>
                                <p style="text-align:center;" v-if="(!filterTags.loading) && (filterTags.list.length == 0)">No Tags Available</p>
                                <ul v-else>
                                    <li 
                                        @click.prevent="setFilter(item, 'tag')" 
                                        v-for="(item, index) in filterTags.list" 
                                        :key="'item_tag_' + index" 
                                        :class="findInFilter(item, 'tags') ? 'active' : '' "
                                    >
                                        <el-tooltip class="box-item" effect="dark" :content="item" placement="bottom" v-if="item.length > 15">
                                            <button>
                                                {{ item.length > 15 ? item.substr(0, 15) + "..." : item }}
                                            </button>
                                        </el-tooltip>
                                        <button v-else>
                                            {{ item }}
                                        </button>
                                    </li>
                                    <li v-if="(filterTags.loading) && (filterTags.list.length > 0)">
                                        <vue-skeleton-loader
                                            type="rect"
                                            :width="124"
                                            :height="35"
                                            animation="fade"
                                        />
                                    </li>
                                    <li 
                                        v-if="(filterTags.currentPage != filterTags.lastPage) && 
                                        (filterTags.total > filterTags.list.length) && 
                                        (!filterTags.loading)"
                                    >
                                        <button style="display:flex; justify-content: center; align-items: center; gap: 10px;" @click.prevent="loadProductBundleTags('more')">
                                            <i class="fa fa-plus" aria-hidden="true"></i>
                                            Load More
                                        </button>
                                    </li>
                                </ul>
                            </div>
                            <div class="categoryFilterBtns">
                                <h6>STATUS</h6>
                                <ul>
                                    <li @click.prevent="setFilter('active', 'status')" :class="findInFilter('active', 'status') ? 'active' : '' ">
                                        <button>ACTIVE</button>
                                    </li>
                                    <li @click.prevent="setFilter('hide', 'status')" :class="findInFilter('hide', 'status') ? 'active' : '' ">
                                        <button>DISABLED</button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="filterApplyBtn">
                            <button class="resetFilterMainBtn" @click.prevent="clearProductBundleFilters">Reset</button>
                            <button @click="searchProductBundle()">APPLY</button>
                        </div>
                    </div>
                </div>
                <!-- filter popup ends here -->

                <!-- image preview modal starts here -->
                <div class="modalBg" :class=" imagePreview.isOpen ? 'modal-active' : ''">
                    <div class="modalLayers" @click.prevent="imagePreview.isOpen = false"></div>
                    <div class="modalBody">
                        <div class="oderSlideBox productSideBox" style="position: relative; top: 15px; right: 0; width: 100%">
                            <div class="productZoomImg">
                                <img :src="imagePreview.imageUrl" alt="">
                            </div>
                        </div>
                        <div class="modalCloseIcon">
                            <button @click.prevent="imagePreview.isOpen = false">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.026 14.027">
                                    <path id="cross-small" d="M18.969,6.023h0a1.079,1.079,0,0,0-1.525,0L12.5,10.971,7.549,6.023a1.079,1.079,0,0,0-1.525,0h0a1.079,1.079,0,0,0,0,1.525L10.971,12.5,6.023,17.443a1.079,1.079,0,0,0,0,1.525h0a1.079,1.079,0,0,0,1.525,0L12.5,14.021l4.947,4.947a1.079,1.079,0,0,0,1.525,0h0a1.079,1.079,0,0,0,0-1.525L14.021,12.5l4.947-4.947A1.079,1.079,0,0,0,18.969,6.023Z" transform="translate(-5.483 -5.483)" fill="#fd4d5d" stroke="#fd4d5d" stroke-width="0.3"/>
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>
                <!-- image preview modal ends here -->

                <!-- video preview modal starts here -->
                <div class="modalBg" :class="videoPreview.isOpen ? 'modal-active' : ''">
                    <div class="modalLayers" @click.prevent="videoPreview.isOpen = false"></div>
                    <div class="modalBody">
                        <div class="oderSlideBox productSideBox" style="position: relative; top: 15px; right: 0; width: 100%" >
                            <div class="productZoomImg">
                                <video :src="videoPreview.videoUrl" controls></video>
                            </div>
                        </div>
                        <div class="modalCloseIcon">
                            <button @click.prevent="videoPreview.isOpen = false">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.026 14.027">
                                    <path id="cross-small" d="M18.969,6.023h0a1.079,1.079,0,0,0-1.525,0L12.5,10.971,7.549,6.023a1.079,1.079,0,0,0-1.525,0h0a1.079,1.079,0,0,0,0,1.525L10.971,12.5,6.023,17.443a1.079,1.079,0,0,0,0,1.525h0a1.079,1.079,0,0,0,1.525,0L12.5,14.021l4.947,4.947a1.079,1.079,0,0,0,1.525,0h0a1.079,1.079,0,0,0,0-1.525L14.021,12.5l4.947-4.947A1.079,1.079,0,0,0,18.969,6.023Z" transform="translate(-5.483 -5.483)" fill="#fd4d5d" stroke="#fd4d5d" stroke-width="0.3"/>
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>
                <!-- video preview modal ends here -->

            </div>

        </div>

        <!--vue tour-->
        <v-tour name="productBundlesTour" :steps="tourSteps" :options="tourOptions" :callbacks="tourCallbacks"></v-tour>

    </div>
</template>

<script>
import subHeader from "@/components/subHeader.vue";
import moduleStats from "@/components/moduleStats.vue";
import tutorialsAction from "@/components/tutorialsAction.vue";
import _ from 'lodash';
import { mapGetters } from 'vuex';
import {MessageBox, Message, Loading} from 'element-ui'
import loaderBtn from "@/components/loaderBtn.vue";
import DraggableImageUploader from "@/components/DraggableImageUploader.vue";
import imagesUploadDraggable from "@/components/imagesUploadDraggable.vue";
import DraggableVideoUploader from "@/components/DraggableVideoUploader.vue";
import videosUploadDraggable from "@/components/videosUploadDraggable.vue";
import Tag from "@/components/TagInput.vue";
import { VueEditor } from "vue2-editor";

export default {
    metaInfo(){
        return{
            title: 'E-Commerce | Product Bundles | YeetCommerce',
        };
    },
    data() {
        return {
            base_image_url: process.env.VUE_APP_STORE_IMAGE_BASE_URL,

            //search start
            searchedItem: '',
            //search end

            //product bundle start
            previewForm: {
                id: '',
                name: '',
                slug: '',
                description: '',
                image: '',
                meta_title: '',
                meta_description: '',
                files: [],
                defaultFiles: [],
                images: [],
                videoFiles: [],
                defaultVideoFiles: [],
                videos: [],
                tags: [],
                keywords: [],
                bundle_type: 'fixed',
                bundle_items:[],
                discount_type: "none",
                discount_value: "",
                bundle_total: 0,
                discounted_total: 0,
                isActive: true,
                isIndexed: true,
                isFeatured: false,
                isTouched: false,
            },
            defaultPreviewForm: {
                id: '',
                name: '',
                slug: '',
                description: '',
                image: '',
                meta_title: '',
                meta_description: '',
                files: [],
                defaultFiles: [],
                images: [],
                videoFiles: [],
                defaultVideoFiles: [],
                videos: [],
                tags: [],
                keywords: [],
                bundle_type: 'fixed',
                bundle_items:[],
                discount_type: "none",
                discount_value: "",
                bundle_total: 0,
                discounted_total: 0,
                isActive: true,
                isIndexed: true,
                isFeatured: false,
                isTouched: false,
            },
            tempBundle: {
                id: '',
                name: '',
                slug: '',
                description: '',
                image: '',
                meta_title: '',
                meta_description: '',
                files: [],
                defaultFiles: [],
                images: [],
                videoFiles: [],
                defaultVideoFiles: [],
                videos: [],
                tags: [],
                keywords: [],
                bundle_type: 'fixed',
                bundle_items:[],
                discount_type: "none",
                discount_value: "",
                bundle_total: 0,
                discounted_total: 0,
                isActive: true,
                isIndexed: true,
                isFeatured: false,
                isTouched: false,
            },
            tempPreviewBundle: {
                id: '',
                name: '',
                slug: '',
                description: '',
                image: '',
                meta_title: '',
                meta_description: '',
                files: [],
                defaultFiles: [],
                images: [],
                videoFiles: [],
                defaultVideoFiles: [],
                videos: [],
                tags: [],
                keywords: [],
                bundle_type: 'fixed',
                bundle_items:[],
                discount_type: "none",
                discount_value: "",
                bundle_total: 0,
                discounted_total: 0,
                isActive: true,
                isIndexed: true,
                isFeatured: false,
                isTouched: false,
            },
            productBundlesLoading: false,
            productBundlesList: [],
            productBundleSave: false,
            productBundleIsSaving: false,
            //product bundle end

            //product modal start
            productsPopup: {
                isOpen: false,
            },
            productsList: [],
            searchedProduct: '',
            productSearchLoader: false,
            productsPagination:{
                current_page: 0,
                total: 0,
                previous_page: 0,
                last_page: 0,
            },
            //product modal end

            //image preview start
            imagePreview:{
                isOpen: false,
                imageUrl: '',
            },
            //image preview end

            //video preview start
            videoPreview:{
                isOpen: false,
                videoUrl: '',
            },
            //video preview end

            //filter popup start
            filterPopup: {
                isOpen: false,
            },
            filter: {
                status: [],
                tags: [],
                searchedTerm: "",
            },
            allFilters: [],
            tagsList: [],
            filterTags:{
                list: [],
                currentPage: 0,
                lastPage: 0,
                total: 0,
                loading: false,
            },
            //filter popup end

            editorOptions:{
                placeholder: 'Description',
                theme: 'snow',
                modules:{
                    toolbar:{
                        container:[ 
                            [{ "font": [] },],
                            [{'header': [1, 2, 3, 4, 5, 6, false]}],
                            ["blockquote", "code-block"],
                            ['bold', 'italic', 'underline', 'strike'],
                            ["clean"],
                            [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
                            [{ 'script': 'sub'}, { 'script': 'super' }],
                            [{'color': []}, {'background': []}],
                            [{ 'direction': 'rtl' }, { 'align': [] }],
                            ['image']
                        ],
                    }
                }
            },

            checkedItems: [],
            deletingItems: [],
            deleteLoader: false,
            timer: undefined,
            isMasterSearched: false,

            //tour start
            tourOptions: {
                useKeyboardNavigation: false,
                labels: {
                    buttonSkip: 'Skip Tour',
                    buttonPrevious: 'Previous',
                    buttonNext: 'Next',
                    buttonStop: 'Finish Tour'
                }
            },
            tourCallbacks: {
                onPreviousStep: this.handlePreviousStep,
                onNextStep: this.handleNextStep,
                // onStart: this.handleTourStart,
                onStop: this.handleTourStop,
                onFinish: this.handleTourFinish,
                onSkip: this.handleTourSkip
            },
            tourSteps:[
                //header steps
                {
                    target: '#tour-start-btn',
                    content: `<strong>Start Tour</strong><br>Provides insights into the available functionalities within a specific menu, helping you understand what actions you can perform.`,
                    params: {
                        highlight: true,
                        enableScrolling: false,
                        placement: 'bottom'
                    }
                },
                {
                    target: '#master-search',
                    content: `<strong>Master Search</strong><br>Enables you to perform searches from all over the dashboard.`,
                    params: {
                        highlight: true,
                        enableScrolling: false
                    },
                },
                {
                    target: '#add-new-dropDown',
                    content: `<strong>Add New Record</strong><br>Enables you to add new orders, products and categories on the go.`,
                    params: {
                        highlight: true,
                        enableScrolling: false,
                    }
                },
                {
                    target: '#help-link',
                    content: `<strong>Help</strong><br>If you require any assistance, feel free to reach out to us.`,
                    params: {
                        highlight: true,
                        enableScrolling: false
                    }
                },
                {
                    target: '#user-options-dropdown',
                    content: `<strong>User Information</strong><br>Presents essential details about you along with a selection of available options.`,
                    params: {
                        highlight: true,
                        enableScrolling: false
                    }
                },
                //search bar step
                {
                    target: '#product-bundle-search',
                    content: `<strong>Search Product Bundles</strong><br>It enables you to search specific product bundles.`,
                    params: {
                        highlight: true,
                        enableScrolling: false,
                        placement: 'bottom'
                    }
                },
                {
                    target: '#product-bundle-filters',
                    content: `<strong>Product Bundle Search Filters</strong><br>It enables you to search specific product bundles based on multiple available filters.`,
                    params: {
                        highlight: true,
                        enableScrolling: false,
                        placement: 'bottom'
                    }
                },
                //form steps
                {
                    target: '#preview-record',
                    content: `<strong>Product Bundles Table</strong><br>You can preview and edit the added product bundles by clicking on each individual record present in the table.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'top'
                    }
                },
                {
                    target: '#add-new-record',
                    content: `Add a <strong>Product Bundle</strong><br>By clicking this button, you can add a new product bundle and open a form to input and save the details.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'bottom'
                    }
                },
                {
                    target: '#product-bundle-details-preview',
                    content: `<strong>Product Bundle Details Form</strong><br>This form can be used to add details about the product bundle and save them.`,
                    params: {
                        highlight: false,
                        enableScrolling: true,
                        placement: 'bottom'
                    }
                },
                {
                    target: '#bundle-name-field-1',
                    content: `<strong>Product Bundle Name Field</strong><br>You can add the name of product bundle using this field.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'bottom'
                    }
                },
                {
                    target: '#bundle-slug-field',
                    content: `<strong>Product Bundle Slug Field</strong><br>You can add the slug of product bundle using this field.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'bottom'
                    }
                },
                {
                    target: '#bundle-description-field',
                    content: `<strong>Product Bundle Description Field</strong><br>You can add the description of product bundle using this field.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'bottom'
                    }
                },
                {
                    target: '#bundle-images-section',
                    content: `<strong>Product Bundle Images</strong><br>Product bundle images can be added using this option.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'bottom'
                    }
                },
                {
                    target: '#bundle-videos-section',
                    content: `<strong>Product Bundle Videos</strong><br>Product bundle videos can be added using this option.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'bottom'
                    }
                },
                {
                    target: '#product-bundle-tags',
                    content: `<strong>Product Bundle Tags</strong><br>You can add tags of the product bundle using this field.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'bottom'
                    }
                },
                {
                    target: '#product-bundle-is-featured',
                    content: `<strong>Product Bundle Featured Status</strong><br>Product bundle status can be changed between featured and not featured using this field.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'bottom'
                    }
                },
                {
                    target: '#product-bundle-tags-reset-btn',
                    content: `<strong>Product Tags Reset</strong><br>All the added product tags can be deleted by clicking this button.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'bottom'
                    }
                },
                {
                    target: '#bundle-product-section',
                    content: `<strong>Bundle Products Section</strong><br>Added products will be shown here.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'bottom'
                    }
                },
                {
                    target: '#add-product-btn',
                    content: `<strong>Add Products</strong><br>Products can be added to the product bundle by clicking this button.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'left'
                    }
                },
                {
                    target: '#discount-type-field',
                    content: `<strong>Select Discount Type</strong><br>discount type can be added to the product bundle using this field.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'left'
                    }
                },
                {
                    target: '#discount-value-field',
                    content: `<strong>Discount Value Field</strong><br>discount value can be added to the product bundle using this field.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'left'
                    }
                },
                {
                    target: '#bundle-total-section',
                    content: `<strong>Bundle Total Section</strong><br>Calculated bundle total will be shown here.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'bottom'
                    }
                },
                {
                    target: '#product-bundle-keywords',
                    content: `<strong>Product Bundle Keywords</strong><br>You can add keywords of the product bundle using this field.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'bottom'
                    }
                },
                {
                    target: '#meta-title-field',
                    content: `<strong>Product Bundle Meta Title Field</strong><br>You can add the meta title of product bundle using this field.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'bottom'
                    }
                },
                {
                    target: '#meta-description-field',
                    content: `<strong>Product Bundle Meta Description Field</strong><br>You can add the meta description of product bundle using this field.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'bottom'
                    }
                },
                {
                    target: '#product-bundle-status',
                    content: `<strong>Product Bundle Status</strong><br>Product bundle status can be changed between active and inactive using this field.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'right'
                    }
                },
                {
                    target: '#product-bundle-indexed',
                    content: `<strong>Product Bundle Index</strong><br>Product bundle indexed status can be changed using this field.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'left'
                    }
                },
                {
                    target: '#bundle-name-field-3',
                    content: `<strong>Product Bundle Name Field</strong><br>You can also add the name of product bundle using this field.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'bottom'
                    }
                },
                {
                    target: '#bundle-name-field-2',
                    content: `<strong>Product Bundle Name Field</strong><br>You can also add the name of product bundle using this field.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'bottom'
                    }
                },
                {
                    target: '#expand-form',
                    content: `<strong>Expand Form</strong><br>Form can be expanded or collapsed by clicking this button.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'left'
                    }
                },
                {
                    target: '#closeSide',
                    content: `<strong>Close Form</strong><br>Form can be closed using this button.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'left'
                    }
                },
                {
                    target: '#product-bundle-save-btn',
                    content: `<strong>Save Product Bundle Details</strong><br>Added product bundle details can be saved by clicking this button.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'left'
                    }
                },
            ],
            tourIsActive:false,
            //tour end

        }
    },
    components:{
        subHeader,
        tutorialsAction,
        moduleStats,
        loaderBtn,
        DraggableImageUploader,
        imagesUploadDraggable,
        DraggableVideoUploader,
        videosUploadDraggable,

        Tag,

        VueEditor,

    },
    computed:{
        ...mapGetters({
            settings: 'settings_module/settings',
            storeProductBundles: 'product_bundles_module/product_bundles',
            storeProductBundlesCurrentPage: 'product_bundles_module/currentPage',
            storeProductBundlesLastPage: 'product_bundles_module/lastPage',
            storeFilters: "product_bundles_module/filters",
            storeAllFilters: "product_bundles_module/allFilters",
            productBundlesSearchKeyword:'product_bundles_module/keyword',
            isPending: 'product_bundles_module/isPending',
        }),
        calculcateBundleTotal(){

            if(this.previewForm.id == -1){

                if(this.previewForm.bundle_items.length > 0){

                    let bundleTotal = 0;

                    if(this.previewForm.discount_type == 'percentage' &&
                    this.previewForm.discount_value &&
                    this.previewForm.discount_value != 0){

                        this.previewForm.bundle_items.forEach((item) => {

                            let total = item.min_quantity * item.unit_price

                            bundleTotal = bundleTotal + total

                        });

                        this.previewForm.bundle_total = bundleTotal

                        let discountedAmount = (this.previewForm.bundle_total * (this.previewForm.discount_value / 100)).toFixed(2);

                        this.previewForm.discounted_total = (this.previewForm.bundle_total - discountedAmount).toFixed(2)

                    }else if(this.previewForm.discount_type == 'flat' &&
                    this.previewForm.discount_value &&
                    this.previewForm.discount_value != 0){
                        
                        this.previewForm.bundle_items.forEach((item) => {

                            let total = item.min_quantity * item.unit_price

                            bundleTotal = bundleTotal + total

                        });

                        this.previewForm.bundle_total = (bundleTotal).toFixed(2);

                        this.previewForm.discounted_total = (this.previewForm.bundle_total - this.previewForm.discount_value).toFixed(2)

                    }else{

                        this.previewForm.bundle_items.forEach((item) => {

                            let total = item.min_quantity * item.unit_price
                            
                            bundleTotal = bundleTotal + total

                        });

                        this.previewForm.bundle_total = (bundleTotal).toFixed(2);

                        this.previewForm.discounted_total = this.previewForm.bundle_total;

                    }

                    bundleTotal = (bundleTotal).toFixed(2)

                    return bundleTotal;

                }else{

                    this.previewForm.bundle_total = 0

                    this.previewForm.discounted_total = 0

                    return 0;

                }

            }else{

                if(this.previewForm.bundle_items.length > 0){

                    let bundleTotal = 0;

                    if(this.previewForm.discount_type == 'percentage' &&
                    this.previewForm.discount_value &&
                    this.previewForm.discount_value != 0){

                        this.previewForm.bundle_items.forEach((item) => {

                            let total = item.min_quantity * item.unit_price

                            bundleTotal = bundleTotal + total

                        });

                        this.previewForm.bundle_total = bundleTotal

                        let discountedAmount = (this.previewForm.bundle_total * (this.previewForm.discount_value / 100)).toFixed(2);

                        this.previewForm.discounted_total = (this.previewForm.bundle_total - discountedAmount).toFixed(2)

                    }else if(this.previewForm.discount_type == 'flat' &&
                    this.previewForm.discount_value &&
                    this.previewForm.discount_value != 0){
                        
                        this.previewForm.bundle_items.forEach((item) => {

                            let total = item.min_quantity * item.unit_price

                            bundleTotal = bundleTotal + total

                        });

                        this.previewForm.bundle_total = (bundleTotal).toFixed(2);

                        this.previewForm.discounted_total = (this.previewForm.bundle_total - this.previewForm.discount_value).toFixed(2)

                    }else{

                        this.previewForm.bundle_items.forEach((item) => {

                            let total = item.min_quantity * item.unit_price
                            
                            bundleTotal = bundleTotal + total

                        });

                        this.previewForm.bundle_total = (bundleTotal).toFixed(2);

                        this.previewForm.discounted_total = this.previewForm.bundle_total;

                    }
                    
                    bundleTotal = (bundleTotal).toFixed(2)

                    return bundleTotal;

                }else{

                    this.previewForm.bundle_total = 0

                    this.previewForm.discounted_total = 0

                    return 0;

                }

            }

        },
    },
    watch:{
        'storeProductBundles':{
            handler: function (value){

                this.productBundlesLoading = false;

                if(this.previewForm.id == -1){
                    
                    this.productBundlesList = _.cloneDeep(value);

                    this.productBundlesList.unshift(this.previewForm);

                }else if(this.tempPreviewBundle.id != ''){
                    
                    this.productBundlesList = _.cloneDeep(value);

                    this.productBundlesList[this.previewForm.index] = this.previewForm;

                }else{

                    this.productBundlesList = _.cloneDeep(value);

                }

                this.filter = _.cloneDeep(this.storeFilters);
                this.allFilters = _.cloneDeep(this.storeAllFilters);

                if(this.isMasterSearched){

                    this.previewSearchedBundle(this.productBundlesList[0],0);

                    this.isMasterSearched = false;

                }

            },deep:true
        },
        'previewForm':{
            handler:function(value){

                if((value.id == -1) && (value.name || value.slug || value.description || value.bundle_items.length > 0)){

                    value.isTouched = true;

                }else{

                    value.isTouched = false;

                }

            },deep:true
        },
    },
    methods:{

        //infinite scroll start
        handleScrollEvent(event){

            let element = this.$refs.productBundlesTable;

            let maxScrollTop = element.scrollHeight - element.clientHeight;

            if(element.scrollTop >= maxScrollTop - 100){

                if(this.isPending == false && this.storeProductBundlesCurrentPage != this.storeProductBundlesLastPage){

                    this.$store.dispatch('product_bundles_module/fetchProductBundlesRequest',{ size:15, page: this.storeProductBundlesCurrentPage + 1, loadData: 'more' });

                }

            }

        },
        //infinite scroll end

        //slug management starts here
        slugGeneration(field){

            let slug = '';

            if(field == 'name'){

                slug = this.previewForm.name.toLowerCase().trim();
                slug = slug.replace(/[^a-zA-Z0-9 ]/g, '');
                slug = slug.replace(/\s/g, '-');
                slug = slug.replace(/(\W)\W*/g, '$1');
                slug = slug.replace(/-$/, "");

            }else if(field == 'slug'){

                slug = this.previewForm.slug.toLowerCase().trim();
                slug = slug.replace(/[^a-zA-Z0-9- ]/g, '');
                slug = slug.replace(/\s/g, '-');
                slug = slug.replace(/(\W)\W*/g, '$1');

            }

            this.previewForm.slug = slug;

        },
        //slug management ends here

        //OTG update start
        bundleSelection(listedBundle){

            this.tempBundle = _.cloneDeep(listedBundle);

        },
        async handleOTGUpdate(listedBundle,index){

            if(this.previewForm.id == ''){

                if(listedBundle.id != -1){
                
                    let isRequired = false;

                    if(listedBundle.name.length == 0){

                        this.$notify({
                            type: 'warning',
                            title: 'Alert',
                            message: "The bundle name field is required.",
                        });

                        isRequired = true;

                    }

                    if(listedBundle.name.length > 250){

                        this.$notify({
                            type: 'warning',
                            title: 'Alert',
                            message: "The bundle name field may not be greater than 250 characters.",
                        });

                        isRequired = true;

                    }

                    if(isRequired){

                        this.productBundlesList[index].name = this.tempBundle.name;

                    }

                    let isChanged = false

                    if(this.tempBundle.name != listedBundle.name){

                        isChanged = true;

                    }

                    if(isChanged){

                        MessageBox.confirm(
                            'You have unsaved changes, save and proceed?',
                            'Confirm',
                            {
                                distinguishCancelAndClose: true,
                                confirmButtonText: 'Save',
                                cancelButtonText: 'Discard Changes',
                            }
                        ).then(async () => {

                            let loader = Loading.service({
                                text: "The product bundle is being updated. Please wait!",
                                fullscreen: true,
                            });

                            let formData = new FormData();

                            formData.append('bundle_id',listedBundle.id);
                            formData.append('field','name');
                            formData.append('name',listedBundle.name);

                            try{
                                let res = await this.$axios.post('/product-bundle/selective/update',formData);
                                if(res.data.status_code == "1763"){

                                    this.$notify({
                                        type: "success",
                                        title: "Success",
                                        message: res.data.message,
                                    });

                                    let payload = {
                                        recordIndex: index,
                                        field: "name",
                                        productBundle: res.data.product_bundle
                                    }

                                    this.$store.commit('product_bundles_module/update_product_bundles_list',payload);

                                    this.tempBundle = [];

                                }
                            }catch(error){

                                this.productBundlesList[index].name = this.tempBundle.name;                   

                                this.tempBundle = [];

                                if(error.response){

                                    if(error.response.data.error.name){

                                        this.$message({
                                            type: "error",
                                            showClose: true,
                                            message: error.response.data.error.name[0],
                                        });

                                    }else if(error.response.data.error.includes("deleted or isn't available")){

                                        this.$message({
                                            type: "error",
                                            showClose: true,
                                            message: error.response.data.error,
                                        });

                                    }else{

                                        this.$message({
                                            type: "error",
                                            showClose: true,
                                            message: error.response.data.message,
                                        });

                                    }

                                }else{

                                    this.$message({
                                        type: "error",
                                        showClose: true,
                                        message: error.message,
                                    });

                                }

                            }finally{

                                loader.close();

                            }

                        }).catch(() => {

                            this.productBundlesList[index].name = this.tempBundle.name;                   

                            this.tempBundle = [];

                            this.$notify({
                                type: "info",
                                title: "Changes Discarded",
                                message: "Changes have been successfully discarded.",
                            });

                        })

                    }

                }

            }else{

                if(listedBundle.id != -1){

                    if(listedBundle.id != this.previewForm.id){

                        let isRequired = false;

                        if(listedBundle.name.length == 0){

                            this.$notify({
                                type: 'warning',
                                title: 'Alert',
                                message: "The bundle name field is required.",
                            });

                            isRequired = true;

                        }

                        if(listedBundle.name.length > 250){

                            this.$notify({
                                type: 'warning',
                                title: 'Alert',
                                message: "The bundle name field may not be greater than 250 characters.",
                            });

                            isRequired = true;

                        }

                        if(isRequired){

                            this.productBundlesList[index].name = this.tempBundle.name;

                        }

                        let isChanged = false

                        if(this.tempBundle.name != listedBundle.name){

                            isChanged = true;

                        }

                        if(isChanged){

                            MessageBox.confirm(
                                'You have unsaved changes, save and proceed?',
                                'Confirm',
                                {
                                    distinguishCancelAndClose: true,
                                    confirmButtonText: 'Save',
                                    cancelButtonText: 'Discard Changes',
                                }
                            ).then(async () => {

                                let loader = Loading.service({
                                    text: "The product bundle is being updated. Please wait!",
                                    fullscreen: true,
                                });

                                let formData = new FormData();

                                formData.append('bundle_id',listedBundle.id);
                                formData.append('field','name');
                                formData.append('name',listedBundle.name);

                                try{
                                    let res = await this.$axios.post('/product-bundle/selective/update',formData);
                                    if(res.data.status_code == "1763"){

                                        this.$notify({
                                            type: "success",
                                            title: "Success",
                                            message: res.data.message,
                                        });

                                        let payload = {
                                            recordIndex: index - 1,
                                            field: "name",
                                            productBundle: res.data.product_bundle
                                        }

                                        this.$store.commit('product_bundles_module/update_product_bundles_list',payload);

                                        this.tempBundle = [];

                                    }
                                }catch(error){

                                    this.productBundlesList[index].name = this.tempBundle.name;                   

                                    this.tempBundle = [];

                                    if(error.response){

                                        if(error.response.data.error.name){

                                            this.$message({
                                                type: "error",
                                                showClose: true,
                                                message: error.response.data.error.name[0],
                                            });

                                        }else if(error.response.data.error.includes("deleted or isn't available")){

                                            this.$message({
                                                type: "error",
                                                showClose: true,
                                                message: error.response.data.error,
                                            });

                                        }else{

                                            this.$message({
                                                type: "error",
                                                showClose: true,
                                                message: error.response.data.message,
                                            });

                                        }

                                    }else{

                                        this.$message({
                                            type: "error",
                                            showClose: true,
                                            message: error.message,
                                        });

                                    }

                                }finally{

                                    loader.close();

                                }

                            }).catch(() => {

                                this.productBundlesList[index].name = this.tempBundle.name;                   

                                this.tempBundle = [];

                                this.$notify({
                                    type: "info",
                                    title: "Changes Discarded",
                                    message: "Changes have been successfully discarded.",
                                });

                            })

                        }


                    }

                }

            }

        },
        //OTG update end

        //preview form open/close & reset start
        previewBundle(listedBundle,i,field,e){

            if(field == 'input'){

                if((e.target.tagName == "TD" || e.target.dataset.open == "true") && !this.tourIsActive){

                    if(listedBundle.id != -1){
                    
                        if(this.previewForm.id == -1){
                        
                            MessageBox.confirm(
                                "Are you sure? unsaved changes will be discarded", 
                                "Warning", 
                                {
                                    confirmButtonText: "Yes",
                                    cancelButtonText: "Cancel",
                                    type: "warning",
                                }
                            ).then(()=>{

                                let index = this.productBundlesList.findIndex((bundle) => bundle.id == -1);

                                if(index != -1){

                                    this.productBundlesList.splice(index,1); 

                                }
                                
                                if(!this.productBundleSave){

                                    let tempIndex = this.productBundlesList.findIndex(x=>x.id == this.tempPreviewBundle.id);

                                    if(tempIndex != -1){

                                        this.productBundlesList[tempIndex] = this.tempPreviewBundle

                                    }

                                }

                                let files = [];

                                if(listedBundle.image){

                                    files.push({
                                    name: listedBundle.image,
                                    url:
                                        this.base_image_url +
                                        this.settings.store_id +
                                        "/product-bundle/" +
                                        listedBundle.image,
                                    });

                                }

                                listedBundle.images.forEach((image) => {

                                    files.push({
                                    id:image.id,
                                    name: image.name,
                                    url:
                                        this.base_image_url +
                                        this.settings.store_id +
                                        "/product-bundle/" +
                                        image.name,
                                    });

                                });

                                listedBundle.defaultFiles = files;
                                listedBundle.files = [];

                                let videos = [];

                                listedBundle.videos.forEach((video) => {

                                    videos.push({
                                        id:video.id,
                                        name: video.name,
                                        url:
                                        this.base_image_url +
                                        this.settings.store_id +
                                        "/product-bundle/" +
                                        video.name,
                                    });

                                });

                                listedBundle.defaultVideoFiles = videos;
                                listedBundle.videoFiles = [];

                                this.previewForm = listedBundle;
                                this.previewForm.index = i;

                                this.checkedItems = [];

                                this.tempPreviewBundle = _.cloneDeep(listedBundle);

                            }).catch(() => {})
                        
                        }else{

                            let index = this.productBundlesList.findIndex((bundle) => bundle.id == -1);

                            if(index != -1){

                                this.productBundlesList.splice(index,1); 

                            }

                            if(!this.productBundleSave && (this.tempPreviewBundle.id != listedBundle.id)){
                                
                                let tempIndex = this.productBundlesList.findIndex(x=>x.id == this.tempPreviewBundle.id);

                                if(tempIndex != -1){

                                    this.productBundlesList[tempIndex] = this.tempPreviewBundle;

                                }

                            }

                            if(this.tempPreviewBundle.id != '' && this.tempPreviewBundle.id == listedBundle.id){

                                let tempIndex = this.productBundlesList.findIndex(x=>x.id == this.tempPreviewBundle.id);

                                if(tempIndex != -1){
                                
                                    this.productBundlesList[tempIndex] = _.cloneDeep(this.storeProductBundles[tempIndex]);
                                
                                    let bundle = _.cloneDeep(this.productBundlesList[tempIndex]);

                                    let files = [];

                                    if(bundle.image){

                                        files.push({
                                        name: bundle.image,
                                        url:
                                            this.base_image_url +
                                            this.settings.store_id +
                                            "/product-bundle/" +
                                            bundle.image,
                                        });

                                    }

                                    bundle.images.forEach((image) => {

                                        files.push({
                                        id:image.id,
                                        name: image.name,
                                        url:
                                            this.base_image_url +
                                            this.settings.store_id +
                                            "/product-bundle/" +
                                            image.name,
                                        });

                                    });

                                    bundle.defaultFiles = files;
                                    bundle.files = [];

                                    let videos = [];

                                    bundle.videos.forEach((video) => {

                                        videos.push({
                                            id:video.id,
                                            name: video.name,
                                            url:
                                            this.base_image_url +
                                            this.settings.store_id +
                                            "/product-bundle/" +
                                            video.name,
                                        });

                                    });

                                    bundle.defaultVideoFiles = videos;
                                    bundle.videoFiles = [];

                                    this.previewForm = bundle;
                                    this.previewForm.index = i;

                                    this.checkedItems = [];

                                    this.tempPreviewBundle = _.cloneDeep(bundle);

                                }   

                            }else{

                                let files = [];

                                if(listedBundle.image){

                                    files.push({
                                    name: listedBundle.image,
                                    url:
                                        this.base_image_url +
                                        this.settings.store_id +
                                        "/product-bundle/" +
                                        listedBundle.image,
                                    });

                                }

                                listedBundle.images.forEach((image) => {

                                    files.push({
                                    id:image.id,
                                    name: image.name,
                                    url:
                                        this.base_image_url +
                                        this.settings.store_id +
                                        "/product-bundle/" +
                                        image.name,
                                    });

                                });

                                listedBundle.defaultFiles = files;
                                listedBundle.files = [];

                                let videos = [];

                                listedBundle.videos.forEach((video) => {

                                    videos.push({
                                        id:video.id,
                                        name: video.name,
                                        url:
                                        this.base_image_url +
                                        this.settings.store_id +
                                        "/product-bundle/" +
                                        video.name,
                                    });

                                });

                                listedBundle.defaultVideoFiles = videos;
                                listedBundle.videoFiles = [];

                                this.previewForm = listedBundle;
                                this.previewForm.index = i;

                                this.checkedItems = [];

                                this.tempPreviewBundle = _.cloneDeep(listedBundle);

                            }

                        }

                    }

                }

            }else{

                if(!this.tourIsActive){
                
                    if(listedBundle.id != -1){
                    
                        if(this.previewForm.id == -1){
                        
                            MessageBox.confirm(
                                "Are you sure? unsaved changes will be discarded", 
                                "Warning", 
                                {
                                    confirmButtonText: "Yes",
                                    cancelButtonText: "Cancel",
                                    type: "warning",
                                }
                            ).then(()=>{

                                let index = this.productBundlesList.findIndex((bundle) => bundle.id == -1);

                                if(index != -1){

                                    this.productBundlesList.splice(index,1); 

                                }
                                
                                if(!this.productBundleSave){

                                    let tempIndex = this.productBundlesList.findIndex(x=>x.id == this.tempPreviewBundle.id);

                                    if(tempIndex != -1){

                                        this.productBundlesList[tempIndex] = this.tempPreviewBundle

                                    }

                                }

                                let files = [];

                                if(listedBundle.image){

                                    files.push({
                                    name: listedBundle.image,
                                    url:
                                        this.base_image_url +
                                        this.settings.store_id +
                                        "/product-bundle/" +
                                        listedBundle.image,
                                    });

                                }

                                listedBundle.images.forEach((image) => {

                                    files.push({
                                    id:image.id,
                                    name: image.name,
                                    url:
                                        this.base_image_url +
                                        this.settings.store_id +
                                        "/product-bundle/" +
                                        image.name,
                                    });

                                });

                                listedBundle.defaultFiles = files;
                                listedBundle.files = [];
                                
                                let videos = [];

                                listedBundle.videos.forEach((video) => {

                                    videos.push({
                                        id:video.id,
                                        name: video.name,
                                        url:
                                        this.base_image_url +
                                        this.settings.store_id +
                                        "/product-bundle/" +
                                        video.name,
                                    });

                                });

                                listedBundle.defaultVideoFiles = videos;
                                listedBundle.videoFiles = [];

                                this.previewForm = listedBundle;
                                this.previewForm.index = i;

                                this.checkedItems = [];

                                this.tempPreviewBundle = _.cloneDeep(listedBundle);

                            }).catch(() => {})
                        
                        }else{

                            let index = this.productBundlesList.findIndex((bundle) => bundle.id == -1);

                            if(index != -1){

                                this.productBundlesList.splice(index,1); 

                            }

                            if(!this.productBundleSave && (this.tempPreviewBundle.id != listedBundle.id)){
                                
                                let tempIndex = this.productBundlesList.findIndex(x=>x.id == this.tempPreviewBundle.id);

                                if(tempIndex != -1){

                                    this.productBundlesList[tempIndex] = this.tempPreviewBundle;

                                }

                            }

                            if(this.tempPreviewBundle.id != '' && this.tempPreviewBundle.id == listedBundle.id){

                                let tempIndex = this.productBundlesList.findIndex(x=>x.id == this.tempPreviewBundle.id);

                                if(tempIndex != -1){

                                    this.productBundlesList[tempIndex] = _.cloneDeep(this.storeProductBundles[tempIndex]);

                                }

                                let bundle = _.cloneDeep(this.productBundlesList[tempIndex]);

                                let files = [];

                                if(bundle.image){

                                    files.push({
                                    name: bundle.image,
                                    url:
                                        this.base_image_url +
                                        this.settings.store_id +
                                        "/product-bundle/" +
                                        bundle.image,
                                    });

                                }

                                bundle.images.forEach((image) => {

                                    files.push({
                                    id:image.id,
                                    name: image.name,
                                    url:
                                        this.base_image_url +
                                        this.settings.store_id +
                                        "/product-bundle/" +
                                        image.name,
                                    });

                                });

                                bundle.defaultFiles = files;
                                bundle.files = [];

                                let videos = [];

                                bundle.videos.forEach((video) => {

                                    videos.push({
                                        id:video.id,
                                        name: video.name,
                                        url:
                                        this.base_image_url +
                                        this.settings.store_id +
                                        "/product-bundle/" +
                                        video.name,
                                    });

                                });

                                bundle.defaultVideoFiles = videos;
                                bundle.videoFiles = [];

                                this.previewForm = bundle;
                                this.previewForm.index = i;

                                this.checkedItems = [];

                                this.tempPreviewBundle = _.cloneDeep(bundle);

                            }else{

                                let files = [];

                                if(listedBundle.image){

                                    files.push({
                                    name: listedBundle.image,
                                    url:
                                        this.base_image_url +
                                        this.settings.store_id +
                                        "/product-bundle/" +
                                        listedBundle.image,
                                    });

                                }

                                listedBundle.images.forEach((image) => {

                                    files.push({
                                    id:image.id,
                                    name: image.name,
                                    url:
                                        this.base_image_url +
                                        this.settings.store_id +
                                        "/product-bundle/" +
                                        image.name,
                                    });

                                });

                                listedBundle.defaultFiles = files;
                                listedBundle.files = [];

                                let videos = [];

                                listedBundle.videos.forEach((video) => {

                                    videos.push({
                                        id:video.id,
                                        name: video.name,
                                        url:
                                        this.base_image_url +
                                        this.settings.store_id +
                                        "/product-bundle/" +
                                        video.name,
                                    });

                                });

                                listedBundle.defaultVideoFiles = videos;
                                listedBundle.videoFiles = [];

                                this.previewForm = listedBundle;
                                this.previewForm.index = i;

                                this.checkedItems = [];

                                this.tempPreviewBundle = _.cloneDeep(listedBundle);

                            }

                        }
                    
                    }
                
                }

            }

        },
        previewSearchedBundle(listedBundle,i){

            let index = this.productBundlesList.findIndex((bundle) => bundle.id == -1);

            if(index != -1){

                this.productBundlesList.splice(index,1); 

            }

            if(!this.productBundleSave){

                let tempIndex = this.productBundlesList.findIndex(x=>x.id == this.tempPreviewBundle.id);

                if(tempIndex  != -1){

                    this.productBundlesList[tempIndex] = this.tempPreviewBundle

                }

            }

            let files = [];

            if(listedBundle.image){

                files.push({
                name: listedBundle.image,
                url:
                    this.base_image_url +
                    this.settings.store_id +
                    "/product-bundle/" +
                    listedBundle.image,
                });

            }

            listedBundle.images.forEach((image) => {

                files.push({
                id:image.id,
                name: image.name,
                url:
                    this.base_image_url +
                    this.settings.store_id +
                    "/product-bundle/" +
                    image.name,
                });

            });

            listedBundle.defaultFiles = files;
            listedBundle.files = [];

            let videos = [];

            listedBundle.videos.forEach((video) => {

                videos.push({
                    id:video.id,
                    name: video.name,
                    url:
                    this.base_image_url +
                    this.settings.store_id +
                    "/product-bundle/" +
                    video.name,
                });

            });

            listedBundle.defaultVideoFiles = videos;
            listedBundle.videoFiles = [];

            this.previewForm = listedBundle;
            this.previewForm.index = i;

            this.checkedItems = [];

            this.tempPreviewBundle = _.cloneDeep(listedBundle);

        },
        async closePreview(){

            if(this.previewForm.isTouched){

                MessageBox.confirm(
                    "Do you want to close? Entered information will be discarded.", 
                    "Warning", 
                    { 
                        confirmButtonText: "Yes",
                        cancelButtonText: "Cancel",
                        type: "warning",
                    }
                ).then(async () => {

                    let imagesObj = {
                        form_type: this.previewForm.id == -1 ? 'add' : 'update',
                        imagesList: [],
                    }

                    let videosObj = {
                        form_type: this.previewForm.id == -1 ? 'add' : 'update',
                        videosList: [],
                    }

                    if(this.previewForm.id == -1){

                        if(this.previewForm.files.length > 0){

                            imagesObj.imagesList = JSON.stringify(this.previewForm.files.filter((img) => img.status == "success"));

                        }

                        if(this.previewForm.videos.length > 0){

                            videosObj.videosList = JSON.stringify(this.previewForm.videos.filter((video) => video.status == "success"));

                        }

                    }

                    let index = this.productBundlesList.findIndex((bundle) => bundle.id == -1);

                    if(index != -1){

                        this.productBundlesList.splice(index,1); 

                    }

                    if(!this.productBundleSave){

                        let tempIndex = this.productBundlesList.findIndex(x=>x.id == this.tempPreviewBundle.id);

                        if(tempIndex != -1){

                            this.productBundlesList[tempIndex] = this.tempPreviewBundle;

                        }

                    }

                    this.previewForm.id = '';

                    // this.previewForm = _.cloneDeep(this.defaultPreviewForm);

                    this.tempPreviewBundle = _.cloneDeep(this.defaultPreviewForm);

                    this.productBundleSave = false;

                    if(imagesObj.form_type == 'add' && imagesObj.imagesList.length > 0){

                        //images removal
                        let formData = new FormData();

                        formData.append("images",imagesObj.imagesList);

                        formData.append('form_type','add');

                        let res = await this.$axios.post("/product-bundle/image/remove/all", formData);
                        if(res.data.status_code == "1767"){

                            this.$store.commit('settings_module/update_remaining_space',res.data.remaining_space.usage_space);

                        }

                    }

                    if(videosObj.form_type == 'add' && videosObj.videosList.length > 0){

                        //videos removal
                        let videoFromData = new FormData();

                        videoFromData.append("videos",videosObj.videosList);

                        let videosRes = await this.$axios.post('/product-bundle/video/remove/all',videoFromData)
                        if(videosRes.data.status_code == '5001'){

                            this.$store.commit('settings_module/update_remaining_space',videosRes.data.remaining_space.usage_space);

                        }

                    }

                    this.$refs.productBundlesFormObserver.reset();

                }).catch(() => {})

            }else{

                let imagesObj = {
                    form_type: this.previewForm.id == -1 ? 'add' : 'update',
                    imagesList: [],
                }

                let videosObj = {
                    form_type: this.previewForm.id == -1 ? 'add' : 'update',
                    videosList: [],
                }

                if(this.previewForm.id == -1){

                    if(this.previewForm.files.length > 0){

                        imagesObj.imagesList = JSON.stringify(this.previewForm.files.filter((img) => img.status == "success"));

                    }

                    if(this.previewForm.videos.length > 0){

                        videosObj.videosList = JSON.stringify(this.previewForm.videos.filter((video) => video.status == "success"));

                    }

                }else if(this.previewForm.id != ''){
                    
                    if(this.previewForm.files.length > 0){

                        if(!this.previewForm.files.some(file => file.name == this.tempPreviewBundle.image)){

                            this.tempPreviewBundle.image = null;

                        }
                        
                        let imgs = this.previewForm.files.filter((file) =>{

                            if(this.tempPreviewBundle.images.some( image => image.name == file.name)){

                                return file;

                            }else if(this.tempPreviewBundle.image  == file.name){

                                return file;

                            }

                        });

                        this.tempPreviewBundle.images = imgs.splice(1);

                        this.tempPreviewBundle.image = imgs[0].name;

                    }

                    if(!this.tempPreviewBundle.image && this.tempPreviewBundle.images.length == 0 ){

                        this.$notify({
                            type: "warning",
                            title: "Images Are Missing",
                            message: "Including at least one image enhances the overall bundle experience.",
                        });

                    }

                }

                let index = this.productBundlesList.findIndex((bundle) => bundle.id == -1);

                if(index != -1){

                    this.productBundlesList.splice(index,1); 

                }

                if(!this.productBundleSave){

                    let tempIndex = this.productBundlesList.findIndex(x=>x.id == this.tempPreviewBundle.id);

                    if(tempIndex != -1){

                        this.productBundlesList[tempIndex] = this.tempPreviewBundle;

                    }

                }

                this.previewForm.id = '';

                // this.previewForm = _.cloneDeep(this.defaultPreviewForm);

                this.tempPreviewBundle = _.cloneDeep(this.defaultPreviewForm);

                this.tempBundle = _.cloneDeep(this.defaultPreviewForm);

                this.productBundleSave = false;

                if(imagesObj.form_type == 'add' && imagesObj.imagesList.length > 0){

                    //images removal
                    let formData = new FormData();

                    formData.append("images",imagesObj.imagesList);
                    
                    formData.append('form_type','add');

                    let res = await this.$axios.post("/product-bundle/image/remove/all", formData);
                    if(res.data.status_code == "1767"){

                        this.$store.commit('settings_module/update_remaining_space',res.data.remaining_space.usage_space);

                    }

                }

                if(videosObj.form_type == 'add' && videosObj.videosList.length > 0){

                    //videos removal
                    let videoFromData = new FormData();

                    videoFromData.append("videos",videosObj.videosList);

                    let videosRes = await this.$axios.post('/product-bundle/video/remove/all',videoFromData)
                    if(videosRes.data.status_code == '5001'){

                        this.$store.commit('settings_module/update_remaining_space',videosRes.data.remaining_space.usage_space);

                    }

                }

                this.$refs.productBundlesFormObserver.reset();

            }

        },
        resetPreview(){

            let index = this.productBundlesList.findIndex((bundle) => bundle.id == -1);

            if(index != -1){

                this.productBundlesList.splice(index,1); 

            }

            this.previewForm.id = '';

        },
        createBundle(){

            if(this.productBundlesLoading){

                this.$notify({
                    type: "warning",
                    title: "Loading Data",
                    message: "Please Wait",
                });

                return

            }

            let index = this.productBundlesList.findIndex(x=>x.id == -1);

            if(index != -1){

                // this.$notify({
                //   title: "Can't Add New Bundle",
                //   type: "error",
                //   message: "you have an unsaved product bundle in the list",
                // });

                return

            }

            this.closePreview();

            this.previewForm = _.cloneDeep(this.defaultPreviewForm)

            this.previewForm.id = -1

            let tempProductBundle = this.previewForm

            this.productBundlesList.unshift(tempProductBundle)

            this.checkedItems = [];

            this.$refs.productBundlesFormObserver.reset();

        },
        //preview form open/close & reset end

        //add/update & delete product bundle start
        async validate(){

            this.$refs.productBundlesFormObserver.validate().then((success) => {

                if(!success){

                    const errors = Object.entries(this.$refs.productBundlesFormObserver.errors)
                    .map(([key, value]) => ({ key, value })).filter((error) => error["value"].length);

                    this.$refs.productBundlesFormObserver.refs[errors[0]["key"]].$el.scrollIntoView({behavior: "smooth", block: "center",});

                }else{

                    if(this.previewForm.bundle_items.length == 0 || 
                    this.previewForm.bundle_items.length == 1){

                        this.$notify({
                            type: "info",
                            title: "Alert",
                            message: "At least two products must be added in the bundle.",
                        });

                        return;

                    }else{

                        if(this.previewForm.id == -1){

                            this.addBundleHandle();

                        }else{

                            this.updateBundleHandle();

                        }

                    }

                }

            });

        },
        async addBundleHandle(){

            if(this.previewForm.files.length == 0){

                this.$notify({
                    type: "error",
                    title: "Images Missing",
                    message: "Add atleast one image to proceed with saving the product bundle.",
                });

                return 
            }

            if(this.previewForm.tags.length == 0){

                this.$notify({
                    type: "error",
                    title: "Product Bundle Tags Missing",
                    message: "Tags should be added to proceed with saving the product bundle.",
                });

                return

            }

            let filesUploading = false;

            for(let i = 0; i < this.previewForm.files.length ; i++){

                if((this.previewForm.files[i].percentage && this.previewForm.files[i].percentage != 100) || this.previewForm.files[i].status != 'success'){

                    filesUploading = false;

                    break;

                }

            }

            if(filesUploading){

                Message({
                    type: "error",
                    message: "Image(s) upload is in progress.",
                });
                
                return

            }

            let videosUploading = false;

            for(let i = 0; i < this.previewForm.videos.length ; i++){

                if((this.previewForm.videos[i].percentage && this.previewForm.videos[i].percentage != 100) || this.previewForm.videos[i].status != 'success'){

                    videosUploading = true;

                    break;

                }

            }

            if(videosUploading){

                Message({
                    type: "error",
                    message: "Videos(s) upload is in progress.",
                });

                return

            }

            let formData = new FormData();

            formData.append('name',this.previewForm.name);
            formData.append('slug',this.previewForm.slug);
            formData.append('description',this.previewForm.description);

            formData.append('isIndexed',this.previewForm.isIndexed ? 1 : 0);
            formData.append('isFeatured',this.previewForm.isFeatured ? 1 : 0);
            formData.append('isActive',this.previewForm.isActive ? 1 : 0);

            formData.append('bundle_type',this.previewForm.bundle_type);

            formData.append('bundle_items',JSON.stringify(this.previewForm.bundle_items));

            formData.append('discount_type',this.previewForm.discount_type);

            formData.append('bundle_total',this.previewForm.bundle_total);

            if(this.previewForm.discount_type != 'none'){

                formData.append('discount_value',this.previewForm.discount_value);

                formData.append('discounted_total',this.previewForm.discounted_total);

            }

            let filteredImages = this.previewForm.files.filter((img) => img.status == "success");

            for(let i=0 ;i < filteredImages.length ; i++){

                formData.append(`images[${i}][name]`,filteredImages[i]['name']);

            }

            if(this.previewForm.videos.length > 0){
        
                let filteredVideos = this.previewForm.videos.filter((video) => video.status == "success");

                for(let i=0 ;i < filteredVideos.length ; i++){

                    formData.append(`videos[${i}][name]`,filteredVideos[i]['name']);

                }

            }

            formData.append('tags',JSON.stringify(this.previewForm.tags));

            formData.append('keywords',JSON.stringify(this.previewForm.keywords));

            if(this.previewForm.meta_title){

                formData.append('meta_title',this.previewForm.meta_title);

            }

            if(this.previewForm.meta_description){

                formData.append('meta_description',this.previewForm.meta_description);

            }

            this.productBundleIsSaving = true;
            try{
                let res = await this.$axios.post('/product-bundle/add',formData);
                if(res.data.status_code == "1762"){

                    this.$notify({
                        type: 'success',
                        title: 'Success',
                        message: res.data.message,
                    });

                    this.$store.commit('product_bundles_module/add_product_bundles_list',res.data.product_bundle);

                    this.resetPreview();

                    this.loadProductBundleTags('simple');

                }
            }catch(error){

                if(error.response){

                    if(error.response.data.error.name){

                        this.$message({
                            type: 'error',
                            showClose: true,
                            message: error.response.data.error.name[0],
                        });

                    }else if(error.response.data.error.slug){

                        this.$message({
                            type: 'error',
                            showClose: true,
                            message: error.response.data.error.slug[0],
                        });

                    }else{

                        this.$message({
                            type: 'error',
                            showClose: true,
                            message: error.response.data.message,
                        });

                    }

                }else{

                    this.$message({
                        type: "error",
                        showClose: true,
                        message: error.message,
                    });

                }

            }finally{

                this.productBundleIsSaving = false;

            }

        },
        async updateBundleHandle(){

            let formData = new FormData();

            formData.append('bundle_id',this.previewForm.id);

            formData.append('name',this.previewForm.name);
            formData.append('slug',this.previewForm.slug);
            formData.append('description',this.previewForm.description);

            formData.append('isIndexed',this.previewForm.isIndexed ? 1 : 0);
            formData.append('isFeatured',this.previewForm.isFeatured ? 1 : 0);
            formData.append('isActive',this.previewForm.isActive ? 1 : 0);

            formData.append('bundle_type',this.previewForm.bundle_type);

            formData.append('bundle_items',JSON.stringify(this.previewForm.bundle_items));

            formData.append('discount_type',this.previewForm.discount_type);

            formData.append('bundle_total',this.previewForm.bundle_total);

            if(this.previewForm.discount_type != 'none'){

                formData.append('discount_value',this.previewForm.discount_value);

                formData.append('discounted_total',this.previewForm.discounted_total);

            }

            formData.append('tags',JSON.stringify(this.previewForm.tags));

            formData.append('keywords',JSON.stringify(this.previewForm.keywords));

            if(this.previewForm.meta_title){

                formData.append('meta_title',this.previewForm.meta_title);

            }

            if(this.previewForm.meta_description){

                formData.append('meta_description',this.previewForm.meta_description);

            }

            this.productBundleIsSaving = true;
            try{
                let res = await this.$axios.post('/product-bundle/update',formData);
                if(res.data.status_code == "1763"){

                    this.$notify({
                        type: 'success',
                        title: 'Success',
                        message: res.data.message,
                    });

                    res.data.product_bundle.bundle_items = res.data.product_bundle.bundle_items.map((listedItem) => {

                        listedItem.item_details = JSON.parse(listedItem.item_details);

                        return listedItem;

                    });

                    res.data.product_bundle.tags = res.data.product_bundle.tags.map((tag) => {

                        return tag.name

                    });

                    res.data.product_bundle.keywords = res.data.product_bundle.keywords.map((keyword) => {

                        return keyword.name

                    });

                    let bundleIndex = this.productBundlesList.findIndex((productBundle) => productBundle.id == res.data.product_bundle.id);

                    this.productBundlesList[bundleIndex] = res.data.product_bundle;

                    let payload = {
                        recordIndex: bundleIndex,
                        field: "update",
                        productBundle: res.data.product_bundle
                    }

                    this.$store.commit('product_bundles_module/update_product_bundles_list',payload);

                    if(this.previewForm.id == res.data.product_bundle.id){

                        this.productBundleSave = true;
                    
                        this.closePreview();

                        this.loadProductBundleTags('simple');
                        
                    }

                }
            }catch(error){

                if(error.response){

                    if(error.response.data.error.id){

                        this.$message({
                            type: 'error',
                            showClose: true,
                            message: error.response.data.error.id[0],
                        });

                    }else if(error.response.data.error.includes("deleted or isn't available")){

                        this.$message({
                            type: 'error',
                            showClose: true,
                            message: error.response.data.error,
                        });

                    }else if(error.response.data.error.name){

                        this.$message({
                            type: 'error',
                            showClose: true,
                            message: error.response.data.error.name[0],
                        });

                    }else if(error.response.data.error.slug){

                        this.$message({
                            type: 'error',
                            showClose: true,
                            message: error.response.data.error.slug[0],
                        });

                    }else{

                        this.$message({
                            type: 'error',
                            showClose: true,
                            message: error.response.data.message,
                        });

                    }

                }else{

                    this.$message({
                        type: "error",
                        showClose: true,
                        message: error.message,
                    });

                }

            }finally{

                this.productBundleIsSaving = false;

            }

        },
        deleteBundleHandle(listedBundle,index){

            if(!this.tourIsActive){

                MessageBox.confirm(
                    "Are you sure you want to delete the product bundle?", 
                    "Warning", 
                    {
                        type: "warning",
                        confirmButtonText: "Yes",
                        cancelButtonText: "Cancel",
                    }
                ).then(async () => {

                    let loader = Loading.service({
                        text: "The product bundle is being removed. Please wait!",
                        fullscreen: true,
                    });

                    this.deletingItems.push(listedBundle.id);
                    this.deleteLoader= true;

                    try{
                        let res = await this.$axios.delete(`/product-bundle/remove/${listedBundle.id}`);
                        if(res.data.status_code == "1764"){

                            this.$notify({
                                type: "success",
                                title: "Success",
                                message: res.data.message,
                            });

                            this.productBundlesLoading = true;

                            this.$store.dispatch('product_bundles_module/fetchProductBundlesRequest',{ size: 15 ,page: 1, loadData:'new' });

                            // this.$store.commit('product_bundles_module/delete_product_bundles_list',index);

                            this.$store.commit('settings_module/update_remaining_space',res.data.remaining_space.usage_space);

                            this.loadProductBundleTags('simple');

                            //removing deleted item from checked items list
                            this.checkedItems = this.checkedItems.filter((item) => {

                                if(item != product.id){
                                    
                                    return item;
                                    
                                }

                            });

                        }
                    }catch(error){

                        if(error.response){

                            if(error.response.data.error.includes("deleted or isn't available")){

                                this.$message({
                                    type: "error",
                                    showClose: true,
                                    message: error.response.data.error,
                                });

                            }else{

                                this.$message({
                                    type: "error",
                                    showClose: true,
                                    message: error.response.data.message,
                                });

                            }

                        }else{

                            this.$message({
                                type: "error",
                                showClose: true,
                                message: error.message,
                            });

                        }

                        

                    }finally{

                        loader.close();

                        this.deletingItems = [];
                        this.deleteLoader = false;

                    }

                }).catch(() => {})
            
            }

        },
        async updateBundleStatus(listedBundle,index){

            if(!this.tourIsActive){
                
                let loader = Loading.service({
                    text: "The product bundle is undergoing status update. Please wait!",
                    fullscreen: true,
                });

                let formData = new FormData();

                formData.append('bundle_id',listedBundle.id);
                formData.append('field', "status");
                formData.append('status', listedBundle.isActive ? 0 : 1);
                
                try{
                    let res = await this.$axios.post('/product-bundle/selective/update',formData);
                    if(res.data.status_code == "1763"){

                        this.$notify({
                            type: "success",
                            title: "Success",
                            message: res.data.message,
                        });

                        let payload = {
                            recordIndex: index,
                            field: "status",
                            productBundle: res.data.product_bundle
                        }

                        this.$store.commit('product_bundles_module/update_product_bundles_list',payload);

                    }
                }catch(error){

                    listedBundle.isActive = !listedBundle.isActive;

                    if(error.response){

                        if(error.response.data.error.includes("deleted or isn't available")){

                            this.$message({
                                type: 'error',
                                showClose: true,
                                message: error.response.data.error,
                            });

                        }else{

                            this.$message({
                                type: 'error',
                                showClose: true,
                                message: error.response.data.message,
                            });

                        }

                    }else{

                        this.$message({
                            type: 'error',
                            showClose: true,
                            message: error.message,
                        });

                    }

                }finally{

                    loader.close();

                }

            }

        },
        async bulkActionHandle(action){

            this.resetPreview();

            MessageBox.confirm(
                'Do you really want to make the changes?',
                'Confirm',
                {
                    distinguishCancelAndClose: true,
                    confirmButtonText: 'Yes',
                    cancelButtonText: 'No',
                }
            ).then(async ()=>{

                if(action == 'active' || action == 'disable'){
                
                    let loader = Loading.service({
                        text: "The selected product bundles are undergoing status update. Please wait!",
                        fullscreen: true,
                    });

                    let formData = new FormData();

                    formData.append('resource_type', 'product_bundle');
                    formData.append('resource_ids',JSON.stringify(this.checkedItems));
                    formData.append('action', action);

                    try{
                        let res = await this.$axios.post('/bulk-action',formData);
                        if(res.data.status_code == "2031"){

                            this.$notify({
                                type: 'success',
                                title: 'Success',
                                message: "Product bundles status have been successfully changed.",
                            });

                            let payload = {
                                action: 'statusChange',
                                status: action == "active" ? 1 : 0,
                                listIds: this.checkedItems
                            }

                            this.$store.commit('product_bundles_module/product_bundles_bulk_action',payload);

                            this.checkedItems = [];

                        }
                    }catch(error){

                        if(error.response){

                            this.$message({
                                type: 'error',
                                showClose: true,
                                message: error.response.data.message,
                            });

                        }else{

                            this.$message({
                                type: "error",
                                showClose: true,
                                message: error.message,
                            });

                        }

                    }finally{

                        loader.close();

                    }

                }else if(action == 'delete'){

                    let loader = Loading.service({
                        text: "The selected product bundles are being removed. Please wait!",
                        fullscreen: true,
                    });

                    let formData = new FormData();

                    formData.append('resource_type', 'product_bundle');
                    formData.append('resource_ids',JSON.stringify(this.checkedItems));
                    formData.append('action', action);

                    this.deletingItems = _.cloneDeep(this.checkedItems);
                    this.deleteLoader = true;

                    try{
                        let res = await this.$axios.post('/bulk-action',formData);
                        if(res.data.status_code == "2031"){

                            this.$notify({
                                type: 'success',
                                title: 'Success',
                                message: "Product bundles have been successfully removed.",
                            });

                            // let payload = {
                            //     action: 'delete',
                            //     listIds: this.checkedItems
                            // }

                            this.productBundlesLoading = true;

                            this.$store.dispatch('product_bundles_module/fetchProductBundlesRequest',{ size: 15 ,page: 1, loadData:'new' });

                            // this.$store.commit('product_bundles_module/product_bundles_bulk_action',payload);

                            this.$store.commit('settings_module/update_remaining_space',res.data.remaining_space.usage_space);

                            this.checkedItems = [];

                            this.loadProductBundleTags('simple');

                        }
                    }catch(error){

                        if(error.response){

                            this.$message({
                                type: 'error',
                                showClose: true,
                                message: error.response.data.message,
                            });

                        }else{

                            this.$message({
                                type: "error",
                                showClose: true,
                                message: error.message,
                            });

                        }

                    }finally{

                        loader.close();

                        this.deletingItems = [];
                        this.deleteLoader = false;

                    }

                }

            }).catch(() => {})

        },
        //add/update & delete product bundle end

        //image handling start
        handleImagePreview(file){

            this.imagePreview = {
                isOpen: true,
                imageUrl: file.url
            }

        },
        imageFilesHandler(files){

            if(this.previewForm.id == -1){

                this.previewForm.files = files

                this.previewForm.defaultFiles = files

                if(this.previewForm.files.length > 0){

                    if(this.previewForm.files[0].status){

                        this.previewForm.image = this.previewForm.files[0].name

                    }

                }else{

                    this.previewForm.image = "";

                }

            }else{

                this.previewForm.files = files

                this.previewForm.defaultFiles = files

                this.tempPreviewBundle.images = files;
                
                if(this.previewForm.files.length > 0){

                    if(this.previewForm.files[0].status && this.previewForm.files[0].status == 'success'){

                        this.previewForm.image = this.previewForm.files[0].name

                        this.tempPreviewBundle = this.previewForm.image;

                    }

                }else{

                    this.previewForm.image = ""

                }

            }

        },
        async reorderProductImages(){

            let formData = new FormData()

            formData.append('product_bundle_id',this.previewForm.id);

            for(let i=0 ;i < this.previewForm.defaultFiles.length ; i++){

                if(!this.previewForm.defaultFiles[i].raw){
                    
                    formData.append(`images[${i}][name]`,this.previewForm.defaultFiles[i]['name']);
                    formData.append(`images[${i}][id]`,this.previewForm.defaultFiles[i]['id'] ? this.previewForm.defaultFiles[i]['id'] : 1);
                    formData.append(`images[${i}][current_index]`,this.previewForm.defaultFiles[i]['current_index']);

                }else{

                    formData.append(`images[${i}][name]`,this.previewForm.defaultFiles[i]['name']);
                    formData.append(`images[${i}][id]`,this.previewForm.defaultFiles[i]['id'] ? this.previewForm.defaultFiles[i]['id'] : 1);
                    formData.append(`images[${i}][current_index]`,this.previewForm.defaultFiles[i]['current_index']);

                }

            }

            let loader = Loading.service({
                text: "The new image arrangement is being saved. Please wait!",
                fullscreen: true,
            });

          try{
            let res = await this.$axios.post('/product-bundle/images/reorder',formData);
            if(res.data.status_code == "6000"){

                this.$notify({
                    type: 'success',
                    title: 'Success',
                    message: res.data.message,
                });

                res.data.product_bundle.bundle_items = res.data.product_bundle.bundle_items.map((listedItem) => {

                    listedItem.item_details = JSON.parse(listedItem.item_details);

                    return listedItem;

                });

                res.data.product_bundle.tags = res.data.product_bundle.tags.map((tag) => {

                    return tag.name

                });

                res.data.product_bundle.keywords = res.data.product_bundle.keywords.map((keyword) => {

                    return keyword.name

                });

                let bundleIndex = this.productBundlesList.findIndex((productBundle) => productBundle.id == res.data.product_bundle.id);

                let payload = {
                    recordIndex: bundleIndex,
                    field: "update",
                    productBundle: res.data.product_bundle
                }

                this.$store.commit('product_bundles_module/update_product_bundles_list',payload);


            }
          }catch(error){

            if(error.response){

                if(error.response.data.error.includes("deleted or isn't available")){

                    this.$message({
                        type: "error",
                        showClose: true,
                        message: error.response.data.error,
                    });

                }else{

                    this.$message({
                        type: "error",
                        showClose: true,
                        message: error.response.data.message,
                    });

                }

            }else{

                this.$message({
                    type: 'error',
                    showClose: true,
                    message: error.message,
                });

            }

          }finally{

            loader.close();

          }

        },
        removeProductImages(productBundle){

            let bundleIndex = this.productBundlesList.findIndex((listedProductBundle) => listedProductBundle.id == productBundle.id);

            let payload = {
                recordIndex: bundleIndex,
                productBundle: productBundle,
            };

            this.$store.commit("product_bundles_module/update_product_bundles_list", payload);

            this.tempPreviewBundle.image = productBundle.image;
            this.tempPreviewBundle.images = productBundle.images;

            this.previewForm.image = this.tempPreviewBundle.image;

        },
        //image handling end

        //video handling start
        handleVideoPreview(videoFile){

            this.videoPreview = {
                isOpen: true,
                videoUrl: videoFile.url
            }

        },
        videoFilesHandler(videoFiles){

            if(this.previewForm.id == -1){

                this.previewForm.videos = videoFiles;

                this.previewForm.defaultVideoFiles = videoFiles;

            }else{

                this.previewForm.videoFiles = videoFiles;

                this.previewForm.defaultVideoFiles = videoFiles;

                this.tempPreviewBundle.videos = videoFiles;

            }

        },
        async reorderProductVideos(){

            let formData = new FormData();

            formData.append('product_bundle_id',this.previewForm.id);

            for(let i=0 ;i < this.previewForm.defaultVideoFiles.length ; i++){
                
                if(!this.previewForm.defaultVideoFiles[i].raw){

                    formData.append(`videos[${i}][name]`,this.previewForm.defaultVideoFiles[i]['name']);
                    formData.append(`videos[${i}][id]`,this.previewForm.defaultVideoFiles[i]['id'] ? this.previewForm.defaultVideoFiles[i]['id'] : 1);
                    formData.append(`videos[${i}][current_index]`,this.previewForm.defaultVideoFiles[i]['current_index'])

                }else{

                    formData.append(`videos[${i}][name]`,this.previewForm.defaultVideoFiles[i]['name']);
                    formData.append(`videos[${i}][id]`,this.previewForm.defaultVideoFiles[i]['id'] ? this.previewForm.defaultVideoFiles[i]['id'] : 1);
                    formData.append(`videos[${i}][current_index]`,this.previewForm.defaultVideoFiles[i]['current_index']);

                }

            }

            let loader = Loading.service({
                text: "The new video arrangement is being saved. Please wait!",
                fullscreen: true,
            });

            try{
                let res = await this.$axios.post('/product-bundle/videos/reorder',formData);
                if(res.data.status_code == "6010"){
                
                    this.$notify({
                        type: "success",
                        title: "Success",
                        message: res.data.message,
                    });

                    res.data.product_bundle.bundle_items = res.data.product_bundle.bundle_items.map((listedItem) => {

                        listedItem.item_details = JSON.parse(listedItem.item_details);

                        return listedItem;

                    });


                    res.data.product_bundle.tags = res.data.product_bundle.tags.map((tag) => {

                        return tag.name

                    });

                    res.data.product_bundle.keywords = res.data.product_bundle.keywords.map((keyword) => {

                        return keyword.name

                    });

                    let bundleIndex = this.productBundlesList.findIndex((productBundle) => productBundle.id == res.data.product_bundle.id);

                    let payload = {
                        recordIndex: bundleIndex,
                        field: "update",
                        productBundle: res.data.product_bundle
                    }

                    this.$store.commit('product_bundles_module/update_product_bundles_list',payload);

                }
            }catch(error){

                if(error.response){

                    if(error.response.data.error.includes("deleted or isn't available")){

                        this.$message({
                            type: "error",
                            showClose: true,
                            message: error.response.data.error,
                        });

                    }else{

                        this.$message({
                            type: "error",
                            showClose: true,
                            message: error.response.data.message,
                        });

                    }

                }else{

                    this.$message({
                        type: 'error',
                        showClose: true,
                        message: error.message,
                    });

                }

            }finally{

                loader.close();

            }

        },
        removeProductVideos(productBundle){

            let payload = {
                index: this.previewForm.index,
                productBundle: productBundle,
            };

            this.$store.commit("product_bundles_module/update_product_bundles_list", payload);

            this.tempPreviewBundle.videos = productBundle.videos;

        },
        //video handling end

        //tags handling start
        resetProductTags(){

            if(this.previewForm.tags.length > 0){

                MessageBox.confirm(
                    "Are you sure you want to reset all the added product bundle tags?", 
                    "Warning", 
                    {
                        type: "warning",
                        confirmButtonText: "Yes",
                        cancelButtonText: "No",
                    }
                ).then(() => {

                    this.previewForm.tags = [];

                    this.$notify({
                        type: "success",
                        title: "Tags Reset",
                        message: "Tags have been successfully reset.",
                    });

                }).catch(() => {})

            }

        },
        //tags handling end

        //product popup methods start
        productPaginateChangeHandle(currentPage, pageSize){

            this.searchProducts('search',currentPage);

        },
        async searchProducts(operation, page){

            if(operation == 'simple'){

                this.productSearchLoader = true;
                try{
                    let productsRes = await this.$axios.get("/getAllProducts",{
                        params:{
                            search: this.searchedProduct ? this.searchedProduct : null,
                            offset: 10,
                            page: page ? page : null,
                        }
                    });
                    if(productsRes.data.status_code == "1007"){

                        this.productsList = productsRes.data.products.data;

                        this.productsPagination = {
                            current_page: page,
                            total: productsRes.data.products.total,
                            previous_page:0,
                            last_page: productsRes.data.products.last_page
                        }

                    }
                }catch(error){

                    this.$message({
                        type: 'error',
                        showClose: true,
                        message: error.response ? error.response.data.message : error.message,
                    });

                }finally{

                    this.productSearchLoader = false;

                }

            }else if(operation == 'search'){

                this.productSearchLoader = true;

                clearTimeout(this.timer);

                this.timer = setTimeout( async ()=>{
                    try{
                        let res = await this.$axios.get(`/getAllProducts`,{
                            params:{
                                search: this.searchedProduct ? this.searchedProduct : null,
                                offset: 10,
                                page: page ? page : null,
                            }
                        });
                        if(res.data.status_code == "1007"){

                            this.productsList = res.data.products.data

                            this.productsPagination = {
                                current_page: page,
                                total: res.data.products.total,
                                previous_page:0,
                                last_page: res.data.products.last_page
                            }

                            return true;

                        }
                    }catch(error){

                        this.$message({
                            type: 'error',
                            showClose: true,
                            message: error.response ? error.response.data.message : error.message,
                        });

                    }finally{

                        this.productSearchLoader = false;

                    }

                },500);

            }

        },
        loadAvailableProducts(){

            this.productsPopup.isOpen = false;

            if(this.searchedProduct.length > 0 || this.productsPagination.current_page != 1){

                this.productsPagination = {
                    current_page: 0,
                    total: 0,
                    previous_page: 0,
                    last_page: 0,
                }

                this.searchedProduct = ""
                this.searchProducts('simple', 1);

            }

        },
        addBundleItem(item){

            if(this.previewForm.id == -1){

                let isFound = false;

                this.previewForm.bundle_items.forEach((listedItem) => {

                    if(listedItem.product_variant_id == item.variant.id){

                        isFound = true;

                    }

                });

                if(!isFound){

                    let tempItem = {
                        product_id: item.product_id,
                        product_variant_id: item.variant.id,
                        max_quantity: item.variant.stock,
                        min_quantity: item.variant.qty,
                        unit_price: item.variant.price,
                        item_details: item,
                    };

                    this.previewForm.bundle_items.push(tempItem);

                }else{

                    this.$notify({
                        type: "info",
                        title: "Alert",
                        message: "Product has already been added in the bundle.",
                    });

                }

            }else{

                let isFound = false;

                this.previewForm.bundle_items.forEach((listedItem) => {

                    if(listedItem.product_variant_id == item.variant.id){

                        isFound = true;

                    }

                });

                if(!isFound){

                    let tempItem = {
                        product_id: item.product_id,
                        product_variant_id: item.variant.id,
                        max_quantity: item.variant.stock,
                        min_quantity: item.variant.qty,
                        unit_price: item.variant.price,
                        item_details: item,
                    };

                    this.previewForm.bundle_items.push(tempItem);

                }else{

                    this.$notify({
                        type: "info",
                        title: "Alert",
                        message: "Product has already been added in the bundle.",
                    });

                }

            }

        },
        calculateProductTotal(item){

            if(this.previewForm.id == -1){

                return item.unit_price * item.min_quantity;

            }else{

                return item.unit_price * item.min_quantity;

            }

        },
        removeBundleItem(item, form, index){

            if(form == 'product-modal'){

                this.previewForm.bundle_items.splice(index, 1);

            }else if(form == 'bundle-form'){

                MessageBox.confirm(
                    "Do you really want to remove the item?", 
                    "Warning", 
                    {
                        confirmButtonText: "Yes",
                        cancelButtonText: "Cancel",
                        type: "warning",
                    }
                ).then(()=>{

                    this.previewForm.bundle_items.splice(index, 1);

                }).catch(() => {})

            }

        },
        bundleItemQuantityChangeHandle(action, index){
            
            if(action == 'incr'){

                if(this.previewForm.bundle_items[index].min_quantity >= this.previewForm.bundle_items[index].max_quantity){

                    this.previewForm.bundle_items[index].min_quantity = this.previewForm.bundle_items[index].max_quantity;

                }else{

                    this.previewForm.bundle_items[index].min_quantity++

                    this.previewForm.bundle_items[index].item_details.variant.qty++

                }

            }else if(action == 'decr'){

                if(this.previewForm.bundle_items[index].min_quantity > 1){

                    this.previewForm.bundle_items[index].min_quantity--;

                }else{

                    this.previewForm.bundle_items[index].min_quantity = this.previewForm.bundle_items[index].min_quantity;

                }

            }

        },
        productQuantityChangeCheck(index){

            if(this.previewForm.id == -1){

                if(this.previewForm.bundle_items[index].max_quantity < parseInt(this.previewForm.bundle_items[index].min_quantity)){

                    this.$notify({
                        type: "info",
                        title: "Stock Limit Reached",
                        message: "Cannot add more quantity than available stock.",
                    });

                    this.previewForm.bundle_items[index].min_quantity = this.previewForm.bundle_items[index].max_quantity

                }else if(parseInt(this.previewForm.bundle_items[index].min_quantity) <= 0){

                    this.$notify({
                        type: "info",
                        title: "Invalid Quantity",
                        message: "The quantity entered is not acceptable.",
                    });

                    this.previewForm.bundle_items[index].min_quantity = this.previewForm.bundle_items[index].max_quantity

                }else if(!Number.isInteger(Number(this.previewForm.bundle_items[index].min_quantity))){

                    this.$notify({
                        type: "info",
                        title: "Invalid Quantity",
                        message: "Decimal values are not allowed for quantity.",
                    });

                    this.previewForm.bundle_items[index].min_quantity = this.previewForm.bundle_items[index].max_quantity

                }else if(this.previewForm.bundle_items[index].min_quantity == ''){

                    this.previewForm.bundle_items[index].min_quantity = 1;

                }else{

                    this.previewForm.bundle_items[index].min_quantity = this.previewForm.bundle_items[index].min_quantity

                }

            }else{

                if(this.previewForm.bundle_items[index].max_quantity < parseInt(this.previewForm.bundle_items[index].min_quantity)){

                    this.$notify({
                        type: "info",
                        title: "Stock Limit Reached",
                        message: "Cannot add more quantity than available stock.",
                    });

                    this.previewForm.bundle_items[index].min_quantity = this.tempPreviewBundle.bundle_items[index].min_quantity

                }else if(parseInt(this.previewForm.bundle_items[index].min_quantity) <= 0){

                    this.$notify({
                        type: "info",
                        title: "Invalid Quantity",
                        message: "The quantity entered is not acceptable.",
                    });

                    this.previewForm.bundle_items[index].min_quantity = this.tempPreviewBundle.bundle_items[index].min_quantity

                }else if(!Number.isInteger(Number(this.previewForm.bundle_items[index].min_quantity))){

                    this.$notify({
                        type: "info",
                        title: "Invalid Quantity",
                        message: "Decimal values are not allowed for quantity.",
                    });

                    this.previewForm.bundle_items[index].min_quantity = this.tempPreviewBundle.bundle_items[index].min_quantity

                }else if(this.previewForm.bundle_items[index].min_quantity == ''){

                    this.previewForm.bundle_items[index].min_quantity = this.tempPreviewBundle.bundle_items[index].min_quantity;

                }else{

                    this.previewForm.bundle_items[index].min_quantity = this.previewForm.bundle_items[index].min_quantity

                }

            }

        },
        //product popup methods end

        //filter popup methods start
        findInFilter(value, type){

            if(type == 'status'){

                let find = this.filter.status.find((e) => e == value);

                    if(find == undefined){

                        return false;

                    }else{

                        return true;

                    }

            }else if(type == "tags"){

                let find = this.filter.tags.find((e) => e == value);

                if(find == undefined){

                    return false;

                }else{

                    return true;

                }

            }

        },
        setFilter(value, type){

            if(type == 'status'){

                let find = this.filter.status.find((e) => e == value);

                if(find == undefined){

                    this.filter.status = [];
                    let obj = null

                    if(value == 'hide'){

                        obj = {
                            value : 'Disabled',
                            type: 'status'
                        }
                    
                    }else{

                        obj = {
                            value : 'Active',
                            type: 'status'
                        }

                    }

                    this.allFilters = this.allFilters.filter((item) =>{

                        if(item.type != 'status'){

                            return item;

                        }

                    });

                    this.allFilters.push(obj);

                    this.filter.status.push(value);

                }else{

                    this.filter.status = this.filter.status.filter((item) => {

                        return item != value;

                    });

                    this.allFilters = this.allFilters.filter((item) =>{

                        if(item.type != 'status'){

                            return item;

                        }

                    }); 

                }

            }else if(type =='tag'){

                let find = this.filter.tags.find((e) => e == value);

                if(find == undefined){

                    let obj = {
                        value : value,
                        type: 'tag'
                    }

                    this.allFilters.push(obj);
                    this.filter.tags.push(value);

                }else{

                    this.filter.tags = this.filter.tags.filter((item) => {

                        return item != value;

                    });

                    this.allFilters = this.allFilters.filter((item) =>{

                        return item.value != value;

                    });

                }

            }

        },
        removeFromFilter(filter){

            if(filter.type == 'status'){

                if(filter.value == 'Disabled'){

                    this.setFilter('hide','status');

                }else{

                    this.setFilter('active','status');

                }

            }else{

                this.setFilter(filter.value,filter.type);

            }

            this.searchProductBundle();

        },
        searchProductBundle(){

            this.resetPreview();

            this.checkedItems = [];

            this.filterPopup.isOpen = false;

            this.productBundlesLoading = true;

            if(this.filter.status.length > 0 || 
            this.filter.tags.length > 0 || 
            this.filter.searchedTerm){

                this.$store.commit("product_bundles_module/update_product_bundle_filters",this.filter);
                this.$store.commit("product_bundles_module/update_product_bundle_allFilters",this.allFilters);

            }else{

                this.$store.commit("product_bundles_module/remove_product_bundle_filters");

            }

            this.$store.dispatch('product_bundles_module/fetchProductBundlesRequest',{ size: 15 ,page: 1, loadData:'new' });

        },
        closeFilterPopup(){

            if((this.filter.status.length != this.storeFilters.status.length) ||
            (this.filter.tags.length != this.storeFilters.tags.length)){

                this.filterPopup.isOpen = false;

                this.searchProductBundle();

            }else{

                this.filterPopup.isOpen = false;

            }

        },
        searchProductBundleByName(){

            this.resetPreview();

            this.checkedItems = [];

            this.productBundlesLoading = true;

            clearTimeout(this.timer);

            this.timer = setTimeout(() => {

                if(this.filter.status.length > 0 || 
                this.filter.tags.length > 0 || 
                this.filter.searchedTerm){

                    this.$store.commit("product_bundles_module/update_product_bundle_filters", this.filter);
                    this.$store.commit("product_bundles_module/update_product_bundle_allFilters",this.allFilters);

                    this.$store.dispatch('product_bundles_module/fetchProductBundlesRequest',{ size: 15 ,page: 1, loadData:'new' });

                }else{

                    this.$store.commit("product_bundles_module/remove_product_bundle_filters");

                    this.$store.dispatch('product_bundles_module/fetchProductBundlesRequest',{ size: 15 ,page: 1, loadData:'new' });

                }

            }, 500);

        },
        clearProductBundleFilters(){

            this.filterPopup.isOpen = false;

            this.filter.status = [];
            this.filter.tags = [];

            this.allFilters = [];

            this.searchProductBundle();
            
        },
        clearSearch(){

            this.filter.searchedTerm = ''

            this.searchProductBundle();

        },
        //filter popup methods end

        //description image handler method start here
        async contentImageHandler(file, Editor, cursorLocation, resetUploader){

            if(!this.tourIsActive){
        
                if(file.type == 'image/jpeg' || file.type == 'image/jpg' || file.type == 'image/webp' || file.type == 'image/png'){

                    let loader = Loading.service({
                        text: "Image is being uploaded. Please wait!",
                        fullscreen: true,
                    });

                    let formData = new FormData();

                    formData.append('form_type','add');

                    formData.append("file", file);

                    try{
                        let res = await this.$axios.post('product-bundle/image/upload',formData);
                        if(res.data.status_code == '1765'){
                        
                            const url = this.base_image_url + this.settings.store_id + '/product-bundle/' + res.data.image
                            
                            Editor.insertEmbed(cursorLocation, "image", url);

                        }
                    }catch(error){

                        if(error.response){

                            if(error.response.data.error.includes("storage limit has been reached") || 
                            error.response.data.error.includes('deleted or is no longer available')){

                                this.$message({
                                    type: "error",
                                    showClose: true,
                                    message: error.response.data.error,
                                });

                            }else{

                                this.$message({
                                    type: "error",
                                    showClose: true,
                                    message: error.response.data.message,
                                });

                            }

                        }else{

                            this.$message({
                                type: 'error',
                                showClose: true,
                                message: error.message,
                            });

                        }

                    }finally{

                        loader.close();

                        resetUploader();

                    }

                }else if(file.type.includes('image/')){

                    resetUploader();

                    let uploadedFileType = file.type.slice(6)

                    this.$notify({
                        type: "error",
                        title: "Alert",
                        message: `${uploadedFileType.toUpperCase()} is not a valid file type.`,
                    });

                }else{

                    resetUploader();

                    this.$notify({
                        type: "error",
                        title: "Alert",
                        message: "Uploaded file must be an image.",
                    }); 

                }

            }

        },
        //description image handler method end here

        //loading tags method start
        async loadProductBundleTags(action){

            if(action == 'more'){

                this.filterTags.loading = true;
                try{
                    let res = await this.$axios.get("/product-bundle/tag/all",{
                        params:{
                            offset: 15,
                            page: this.filterTags.currentPage + 1,
                        }
                    });
                    if(res.data.status_code == "1768"){

                        res.data.product_bundle_tags.data.forEach((tag) => {

                            if(!this.filterTags.list.some((listedTags) => listedTags == tag.name)){

                                this.filterTags.list.push(tag.name);

                            }

                        });

                        this.filterTags.currentPage = res.data.product_bundle_tags.current_page;
                        this.filterTags.lastPage = res.data.product_bundle_tags.last_page;

                        this.filterTags.total = res.data.product_bundle_tags.total;

                        if(this.filter.tags.length != 0){

                            let notListedTags = [];

                            this.filter.tags.forEach((tag) => {

                                if(!this.filterTags.list.some((listedTags) => listedTags == tag)){

                                    notListedTags.push(tag);

                                }

                            });

                            if(notListedTags.length > 0){

                                notListedTags.forEach((categoryNotListed) => {

                                    this.filterTags.list.push(categoryNotListed);

                                });

                            }

                        }

                    }
                }catch(error){

                    this.$message({
                        type: 'error',
                        showClose: true,
                        message: error.response ? error.response.data.message : error.message,
                    });

                }finally{

                    this.filterTags.loading = false;

                }

            }else if(action == 'simple'){

                this.filterTags.loading = true;
                try{
                    let res = await this.$axios.get("/product-bundle/tag/all",{
                        params:{
                            offset: 16,
                            page: 1,
                        }
                    });
                    if(res.data.status_code == "1768"){

                        res.data.product_bundle_tags.data.forEach((tag) => {

                            if(!this.filterTags.list.some((listedTags) => listedTags == tag.name)){

                                this.filterTags.list.push(tag.name);

                            }

                        });

                        this.filterTags.currentPage = res.data.product_bundle_tags.current_page;
                        this.filterTags.lastPage = res.data.product_bundle_tags.last_page;

                        this.filterTags.total = res.data.product_bundle_tags.total;

                        if(this.filter.tags.length != 0){

                            let notListedTags = [];

                            this.filter.tags.forEach((tag) => {

                                if(!this.filterTags.list.some((listedTags) => listedTags == tag)){

                                    notListedTags.push(tag);

                                }

                            });

                            if(notListedTags.length > 0){

                                notListedTags.forEach((categoryNotListed) => {

                                    this.filterTags.list.push(categoryNotListed);

                                });

                            }

                        }

                    }
                }catch(error){

                    this.$message({
                        type: 'error',
                        showClose: true,
                        message: error.response ? error.response.data.message : error.message,
                    });

                }finally{

                    this.filterTags.loading = false;

                }

            }

        },
        //loading tags method end

        //other methods start
        isDeletingItem(id){

            let find = this.deletingItems.find(x=>x == id);

            if(find){

                return true;

            }else{

                return false;

            }

        },
        checkedItemHandle(listedBundleId){

            if(!this.tourIsActive && listedBundleId != -1 && !this.previewForm.id){

                let index = this.checkedItems.findIndex(item => item == listedBundleId);

                if(index != -1){

                    this.checkedItems.splice(index,1)

                }else{

                    this.checkedItems.push(listedBundleId)

                }

            }

        },
        toggleIsCheckedAll(){

            if(!this.tourIsActive && !this.previewForm.id){

                if((this.checkedItems.length == this.productBundlesList.length)){

                    this.checkedItems = [];

                }else if(this.checkedItems.length < this.productBundlesList.length){

                    this.checkedItems = [];

                    this.productBundlesList.forEach(bundle => {

                        if(bundle.id != -1){

                            this.checkedItems.push(bundle.id);

                        }

                    });

                }

            }

        },
        clearSelection(){

            this.checkedItems = [];

        },
        expand(){

            this.$refs.viewInputForm.classList.toggle('fullWidthSlide');

        },
        productBundlesKeyEvent(e){

            if(e.key == 'Escape'){

                if(this.$refs.viewInputForm){

                    if(this.$refs.viewInputForm.classList.contains('fullWidthSlide')){

                        this.$refs.viewInputForm.classList.remove('fullWidthSlide');

                    }else if(this.$refs.viewInputForm.classList.contains('activeRow')){

                        this.closePreview();

                    }

                }

            }

        },
        //other methods end

        //tour methods start
        startProductBundlesTour(){

            this.$tours.productBundlesTour.start();

            this.tourIsActive = true;

            if(this.previewForm.id != ''){

                this.resetPreview();

            }

        },
        handleNextStep(currentStep){

            if(currentStep + 1 == 8){

                document.querySelector(".tableActionBtns").style.position = "static";

                document.querySelector(".productSlideBox").style.overflowY  = "hidden";

                this.createBundle();

            }

            if(currentStep == 19){

                this.previewForm.discount_type = 'percentage';

            }

            if(currentStep >= 27){

                document.querySelector(".tableActionBtns").style.position = "sticky";

            }

            if(currentStep + 1 == 30){

                this.expand();

            }

            if(currentStep + 1 == 31){

                if(this.$refs.viewInputForm.classList.contains('fullWidthSlide')){

                    this.expand();

                } 

            }

        },
        handlePreviousStep(currentStep){

            if(currentStep == 8){

                this.resetPreview();

                document.querySelector(".tableActionBtns").style.position = "sticky";

                document.querySelector(".productSlideBox").style.overflowY  = "auto";

            }

            if(currentStep == 20){

                this.previewForm.discount_type = 'none';

            }

            if(currentStep <= 28){

                document.querySelector(".tableActionBtns").style.position = "static";

            }

            if(currentStep == 30){

                if(this.$refs.viewInputForm.classList.contains('fullWidthSlide')){

                    this.expand();

                }

            }

            if(currentStep == 31){

                this.expand();

            }

        },
        handleTourStop(){

            document.querySelector(".tableActionBtns").style.position = "sticky";

            document.querySelector(".productSlideBox").style.overflowY  = "auto";

            this.closePreview();

            this.tourIsActive = false;

        },
        handleTourFinish(){
        
            document.querySelector(".tableActionBtns").style.position = "sticky";

            document.querySelector(".productSlideBox").style.overflowY  = "auto";

            this.closePreview();

            this.tourIsActive = false;
        
        },
        handleTourSkip(){

            document.querySelector(".tableActionBtns").style.position = "sticky";

            document.querySelector(".productSlideBox").style.overflowY  = "auto";

            this.closePreview();

            this.tourIsActive = false;

        },
        //tour methods end

        //image loading error handling
        handleImageError(e){

            e.target.src = '/images/image_not_found.png'

        },

    },
    mounted(){

        this.$refs.productBundlesTable.addEventListener('scroll', this.handleScrollEvent);

        window.addEventListener('keydown',this.productBundlesKeyEvent);

        window.addEventListener('beforeunload', (event) => {

            let index = null;

            this.productBundlesList.forEach((listedBundle) => {

                if(listedBundle.id == -1){

                    index = this.productBundlesList.indexOf(listedBundle);

                }

            });

            if(index != null){

                event.returnValue = "Are you sure you want to leave? entered information will be lost";

            }

        });

        if(this.$route.query.search){
      
            this.filter.searchedTerm = this.$route.query.search

            if(this.$route.query.preview == 1){

                this.isMasterSearched = true;

            }

            this.$router.replace({'query': null});

            this.searchProductBundle();

        }

        //form top navigation scroll handle starts here
        this.$refs.viewInputForm.addEventListener("scroll",(e)=>{

            let maxScrollTop = e.target.scrollHeight - e.target.clientHeight;

            if(e.target.scrollTop >= maxScrollTop){

                document.querySelectorAll(".sectionTabsLink ul li a.active").forEach(function (item) {

                    item.classList.remove("active");

                });

                if(!this.tourIsActive){

                    document.querySelector('#bundle-seo-navbtn').classList.add('active');

                }

            }else{

                document.querySelectorAll(".sectionTabsLink ul li a.active").forEach(function (item) {

                    item.classList.remove("active");

                });

                if(!this.tourIsActive){

                    document.querySelector('#bundle-details-navbtn').classList.add('active');

                }
            
            }

        });
        //form top navigation scroll handle ends here

    },
    async beforeMount(){

        try{

            if((!this.storeProductBundles || this.storeProductBundles.length == 0) && this.productBundlesSearchKeyword.length == 0){
            
                this.productBundlesLoading = true;

                this.$store.dispatch('product_bundles_module/fetchProductBundlesRequest',{ size: 15 ,page: 1, loadData:'new' });

            }else{

                this.productBundlesList = _.cloneDeep(this.storeProductBundles);

                this.productBundlesLoading = false;

                this.filter = _.cloneDeep(this.storeFilters);
                this.allFilters = _.cloneDeep(this.storeAllFilters);

            }

            if(this.productBundlesSearchKeyword.length > 0){

                this.searchedItem = this.productBundlesSearchKeyword;
                
            }

            //fetching products 
            this.searchProducts('simple',1);

            //fetching product bundle tags
            this.loadProductBundleTags('simple');

        }catch(error){

            this.productBundlesLoading = false;

            this.$message({
                type: 'error',
                showClose: true,
                message: error.response ? error.response.data.message : error.message,
            });

        }

    },
    beforeDestroy(){

        window.removeEventListener("keydown", this.productBundlesKeyEvent);

    },
    beforeRouteLeave(to, from, next) {

        let index = null;

        this.productBundlesList.forEach((bundle) => {

            if(bundle.id == -1){

                index = this.productBundlesList.indexOf(bundle);

            }

        });

        if(window.innerWidth <= 1024){

            document.body.classList.remove('activeIpad');
            document.body.classList.add('hideSideBar');

        }

        if((this.previewForm.id || index != null) && to.fullPath != '/login'){

            MessageBox.confirm(
                'You may have unsaved changes, Proceeding will discard them',
                'Confirm',
                {
                    distinguishCancelAndClose: true,
                    confirmButtonText: 'Process',
                    cancelButtonText: 'Stay',
                }
            ).then(()=>{

                this.resetPreview();

                next();

            }).catch(()=>{
                
            })

        }else{

            next()

        }

    },
}

window.myFunction = function (event) {

    // reset all menu items
    document.querySelectorAll(".sectionTabsLink ul li a.active").forEach(function (item) {
        item.classList.remove("active");
    });
    // mark as active selected menu item
    event.target.classList.add("active");

};
</script>

<style scoped>

</style>